import React, { Component } from 'react'
import {
  ImageCenteredText,
  NavLink,
  withSizes,
} from './common'
import deepmerge from 'deepmerge'

class TileBodyImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hovered: false,
    }
  }

  onHover = () => {
    const { hovered } = this.state
    this.setState({
      hovered: !hovered,
    })
  }

  render() {
    const { img, url, txt, getStyle, pageEntry, history } = this.props
    const { hovered } = this.state
    const { onHover } = this

    const _url = pageEntry || url
    const useRouter = pageEntry !== undefined && pageEntry !== null

    const styles = hovered
      ? deepmerge(bodyStyles, bodyStyles.hovered)
      : bodyStyles

    return (
      <NavLink
        item={{ url: _url }}
        useRouter={useRouter}
        history={history}
        onMouseEnter={onHover}
        onMouseLeave={onHover}
        style={getStyle(styles.container)}>
        <ImageCenteredText
          styles={getStyle(styles)}
          image={{ image: img, title: txt }}
          text={txt}
        />
      </NavLink>
    )
  }
}

const bodyStyles = {
  container: {
    height: '100%',
    width: '100%',
    padding: 0,
    transition: 'transform 0.5s',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  text: {
    color: '#FFFFFF',
    fontFamily: 'acumin-pro-condensed, sans-serif',
    letterSpacing: 3,
    textTransform: 'uppercase',
    margin: 0,
    transition: 'color 0.25s',
    lineHeight: 1,
    textAlign: 'left',
    fontSize: 35,
    lg: {
      fontSize: 35,
    },
    md: {
      fontSize: 45,
    },
    xxs: {
      fontSize: 35,
    },
  },
  overlay: {
    padding: 20,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    transition: 'background-color 0.25s',
  },
  navLink: {
    padding: 1,
  },
  hovered: {
    container: {
      transform: 'scale(1.05)',
      sm: {
        transform: 'scale(1.03)',
      },
    },
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.7)',
    },
  },
}

export default withSizes(TileBodyImage)
