import React, { Component } from 'react'
import FooterDesktop from './FooterDesktop'
import FooterMobile from './FooterMobile'
import {withSizes} from './common'
import { gqlConnect } from 'react-connect-graphql-beta'

class Footer extends Component {
  mapDataToColumns = (data) => {
    let addressRows = []
    let contactRows = []
    let aboutRows = []

    data.footerAddressColumn.forEach((address) => {
      if (address.header) {
        return
      }
      addressRows.push(address.addressLine)
    })

    let addressColumn = {
      heading: data.addressHeader,
      type: 'text',
      text: addressRows,
    }

    data.footerContactColumn.forEach((contact) => {
      let contactRow = {}
      if (contact.header) {
        return
      }

      if (contact.phoneNumber) {
        contactRow = {
          title: contact.phoneNumberType + ': ',
          url: contact.phoneNumber,
          type: 'phone',
        }
      } else {
        contactRow = {
          title: contact.emailAddress,
          url:
            'mailto:' +
            contact.emailAddress +
            '?subject=' +
            contact.emailSubject,
          type: 'email',
        }
      }

      contactRows.push(contactRow)
    })

    let contactColumn = {
      heading: data.contactHeader,
      type: 'links',
      links: contactRows,
    }

    data.footerAboutColumn.forEach((about) => {
      let aboutRow = {}

      if (about.header) {
        return
      }

      aboutRow.title = about.displayName
        ? about.displayName
        : about.page[0].title
      aboutRow.url = `/${about.page[0].slug}`

      aboutRows.push(aboutRow)
    })

    let aboutColumn = {
      heading: data.aboutHeader,
      type: 'links',
      links: aboutRows,
    }

    return [addressColumn, contactColumn, aboutColumn]
  }

  render() {
    const { md } = this.props
    const { data } = this.props.gql
    const { mapDataToColumns } = this

    const columns = mapDataToColumns(data.globalSets[1])
    const copyrightYear = new Date().getFullYear()
    const copyright = 'Copyright ' + copyrightYear + ' ' + data.globalSets[1].footerCopyrightNotice
    const socials = data.globalSets[1]?.footerSocialMediaLinks?.map((link) => {
      return {
        name: link.socialName,
        icon: link.socialIcon[0].url,
        url: link.socialUrl,
      }
    })

    return (
      <React.Fragment>
        {md ? (
          <FooterMobile
            columns={columns}
            copyright={copyright}
            socials={socials}
          />
        ) : (
          <FooterDesktop
            columns={columns}
            copyright={copyright}
            socials={socials}
          />
        )}
      </React.Fragment>
    )
  }
}

export default gqlConnect('footer')(withSizes(Footer))
