export const getPostPreview = (item) => {
  let firstBodyEntry = null
  let firstParagraph

  if (item.newsPostBody) {
    firstBodyEntry = item.newsPostBody.find((b) => b.typeHandle === 'text')
      ?.text
    if (firstBodyEntry.includes('<p>')) {
      firstParagraph = firstBodyEntry
        .substring(
          firstBodyEntry.indexOf('<p>') + 3,
          firstBodyEntry.indexOf('</p>'),
        )
        .substring(0, 190)
    } else {
      firstParagraph = firstBodyEntry.substring(0, 190)
    }
    if (firstParagraph[firstParagraph.length - 1] !== '.') {
      firstParagraph += '...'
    }
    firstParagraph = firstParagraph.replace(/(<([^>]+)>)/gi, '')
  }

  if (item.postPreview !== null && item.postPreview !== undefined) {
    return item.postPreview
  } else {
    return firstParagraph
  }
}
