const initState = {
  siteName: null,
  shortSiteName: null,
  titleDelimiter: null,
  siteNameCharacterLimit: null,
}

const metadata = (state = initState, action) => {
  switch(action.type) {
    case 'SET_METADATA':
      return {
        ...state,
        siteName: action?.data?.siteName,
        shortSiteName: action?.data?.shortSiteName,
        titleDelimiter: action?.data?.titleDelimiter,
        siteNameCharacterLimit: action?.data?.siteNameCharacterLimit,
      }
    default:
      return state
  }
}

export default metadata
