import React, { Component } from 'react'
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react'
import { withSizes } from './common'

const MAPS_API_KEY = 'AIzaSyCg9z7kEJUIMPOFsEVPTJ5lkzNqR-anEOg'

class GoogleMapView extends Component {
  render() {
    const { data } = this.props.gql
    const { getStyle } = this.props

    const marker = data[0].pinLocation
    return (
      <Map
        google={this.props.google}
        zoom={13}
        style={styles.map}
        containerStyle={getStyle(styles.mapContainer)}
        initialCenter={{ lat: marker.latitude, lng: marker.longitude }}>
        <Marker position={{ lat: marker.latitude, lng: marker.longitude }} />
      </Map>
    )
  }
}

const styles = {
  mapContainer: {
    overflow: 'hidden',
    position: 'unset',
    width: '100%',
    maxWidth: 1200,
    flex: 1,
    height: 684,
    marginLeft: 'auto',
    marginRight: 'auto',
    //alignSelf: 'stretch',
    sm: {
      marginLeft: -50,
      marginRight: 0,
      width: 'calc(100% + 100px)',
      height: 400,
    },
  },
  map: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
}

export default GoogleApiWrapper({ apiKey: MAPS_API_KEY })(
  withSizes(GoogleMapView),
)
