import React, { Component } from 'react'
import { Parallax, withController } from 'react-scroll-parallax'
import { withSizes } from './common'

class FloatingParallax extends Component {
  constructor(props) {
    super(props)

    this.state = {
      windowWidth: window.innerWidth,
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize)
    setTimeout(() => {
      this.props.parallaxController.update()
    }, 100)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  resize = () => {
    this.setState({
      windowWidth: window.innerWidth,
    })
  }

  getY = (layer = null) => {
    let { smallMax, y, smallY } = this.props
    if (layer) {
      y = layer.y
      smallY = layer.smallY ? layer.smallY : layer.y
    }
    const { windowWidth } = this.state
    if (windowWidth <= smallMax) {
      return smallY
    }
    return y
  }

  layers = [
    {
      image: this.props.gql?.image,
      y: [-20, 20],
      smallY: [-20, 20],
    },
    {
      text: this.props.gql?.cardText,
      y: [25, -25],
      smallY: [10, -10],
    },
  ]

  render() {
    const { layers } = this
    const {
      getStyle,
      image,
      useGql,
      gql,
      showCard,
      cardText,
      marginBottom,
    } = this.props

    const _showCard = useGql ? gql.showCard : showCard

    const styles = _styles(_showCard)

    return (
      <div
        style={getStyle({
          ...styles.container,
          marginBottom: marginBottom || 0,
        })}>
        <Parallax y={this.getY()}>
          <div style={getStyle(styles.floatingContainer)}>
            <div style={getStyle(styles.imageLayer)}>
              <Parallax y={layers[0].y}>
                <img
                  alt={'parallaxImg'}
                  src={useGql ? layers[0].image : image}
                  style={getStyle(styles.image)}
                />
              </Parallax>
            </div>
            {_showCard && (
              <div style={getStyle(styles.cardLayer)}>
                <Parallax y={layers[1].y}>
                  <div
                    style={getStyle({
                      ...styles.card,
                    })}>
                    <div style={getStyle(styles.cardText)}>
                      <div style={getStyle(styles.shadow)} />
                      {useGql ? layers[1].text : cardText}
                    </div>
                  </div>
                </Parallax>
              </div>
            )}
          </div>
        </Parallax>
      </div>
    )
  }
}

FloatingParallax.defaultProps = {
  floatOffset: 75,
  smallFloatOffset: 30,
  y: [10, -10],
  smallY: [7, -7],
  layers: [],
  useGql: true,
  smallMax: 730,
}

const _styles = (showCard) => {
  return {
    container: {
      position: 'relative',
      width: '100vw',
      height: 600,
      overflow: 'show',
      zIndex: 1000,
      marginTop: showCard ? 75 : 0,
      sm: {
        marginTop: showCard ? 175 : 0,
        height: showCard ? 420 : 600,
      },
    },
    floatingContainer: {
      position: 'relative',
      height: 600,
      sm: {
        height: showCard ? 420 : 600,
      },
    },
    card: {
      width: '100%',
      maxWidth: 1270,
      paddingRight: 35,
      paddingLeft: 35,
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      lg: {},
    },
    shadow: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      backgroundColor: '#E86956',
      zIndex: -1,
      left: 8,
      top: 8,
    },
    cardText: {
      width: 'auto',
      position: 'relative',
      maxWidth: 800,
      marginTop: -75,
      backgroundColor: '#EDE8E4',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 35,
      color: '#E86956',
      fontFamily: 'capitolina',
      fontStyle: 'italic',
      fontWeight: 500,
      fontSize: 35,
      lg: {},
      md: {},
      sm: {
        fontSize: 30,
      },
      xxs: {
        fontSize: 25,
      },
    },
    image: {
      position: 'relative',
      width: '100%',
      height: 600,
      objectFit: 'cover',
      sm: {
        height: showCard ? 400 : 600,
      },
    },
    imageLayer: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      marginLeft: '50%',
      transform: 'translateX(-50%)',
      overflow: 'hidden',
    },
    cardLayer: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      marginLeft: '50%',
      transform: 'translateX(-50%)',
    },
  }
}

export default withController(withSizes(FloatingParallax))
