export const _title = 'American Technology Components, Inc.'
export const _description =
  'American Technology Components, Inc. is an innovative, custom manufacturer providing products and services across multiple industries.'
export const _image =
  'https://s3.us-east-2.amazonaws.com/www.atcomp.com/assets/Header.png'
export const _keywords =
  'american, technology, components, manufacture, rv, marine, truck, transit, van, trailer'

export const parseKeywords = (keywords) => {
  let keywordString = ''
  keywords.forEach((key, index) => {
    if (index < keywords.length - 1) {
      keywordString += `${key.keywordTitle},`
    } else {
      keywordString += key.keywordTitle
    }
  })
  return keywordString
}

export const getMeta = (
  title = _title,
  description = _description,
  image = _image,
  keywords = _keywords,
) => {
  return {
    title,
    description,
    meta: {
      charset: 'utf-8',
      name: {
        keywords: keywords,
      },
      itemProp: {
        title,
        description,
        image,
      },
      property: {
        'og:title': title,
        'og:type': 'website',
        'og:image': image,
        'og:site_name': 'American Technology Components, Inc.',
        'og:description': description,
        'og:url': window.location.href,
        'fb:app_id': '781322739028383',
      },
    },
    auto: {
      ograph: true,
    },
  }
}
