import { send } from '../../utils/EmailHandler'
import * as EmailValidator from 'email-validator'

export const sendEmail = (options) => {
  return async (dispatch) => {
    const subject = `New message from ${options.email}`
    const newMessage = `<b>Name:</b> ${options.name}<br /><b>Email:</b> ${options.email}<br /><b>Phone:</b> ${options.phone}<br /><b>Message:</b><br />"${options.message}"`

    const newOptions = {
      ...options,
      subject: subject,
      message: newMessage,
    }

    if (options.name === '' || options.message === '' || options.email === '' || options.phone === '') {
      dispatch(
        setStatusMessage(
          'Please include your name, email address, phone number, and message.',
        ),
      )
      return
    } else if (!EmailValidator.validate(options.email)) {
      dispatch(setStatusMessage('Email address is invalid.'))
      return
    }

    dispatch(setSendState(true))
    dispatch(setStatusMessage(''))

    const status = await send(newOptions)
    setSuccess(status.isSuccessful)
    if (status.isSuccessful) {
      dispatch(setStatusMessage('Message sent successfully.'))
      sendConfirmationEmail(options)
    } else {
      dispatch(setStatusMessage('Unable to send message.'))
    }

    dispatch(setSendState(false))
  }
}

export const resetEmailData = () => {
  return async (dispatch) => {
    dispatch(setStatusMessage(''))
    dispatch(setEmailName(''))
    dispatch(setEmailAddress(''))
    dispatch(setEmailMessage(''))
    dispatch(setPhoneNumber(''))
  }
}

const sendConfirmationEmail = (options) => {
  const message = options.confirmationEmail.replace('{Name}', options.name)

  const newOptions = {
    from: options.from,
    to: options.email,
    message: message,
    subject: options.confirmationEmailSubject,
  }

  send(newOptions).then((status) => {
    console.log(status.isSuccessful)
  })
}

const setSendState = (isSending) => ({
  type: 'SET_SENDING',
  payload: isSending,
})

const setSuccess = (isSuccessful) => ({
  type: 'SET_SUCCESS',
  payload: isSuccessful,
})

const setStatusMessage = (message) => ({
  type: 'SET_STATUS_MESSAGE',
  payload: message,
})

export const setEmailMessage = (message) => ({
  type: 'SET_EMAIL_MESSAGE',
  payload: message,
})

export const setEmailName = (name) => ({
  type: 'SET_NAME',
  payload: name,
})

export const setEmailAddress = (address) => ({
  type: 'SET_ADDRESS',
  payload: address,
})

export const setPhoneNumber = (phone) => ({
    type: 'SET_PHONE',
    payload: phone,
  })