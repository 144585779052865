import React, { Component } from 'react'
import { PathIndicator as PIComp } from './common'
import { withRouter } from 'react-router-dom'

class PathIndicator extends Component {
  render() {
    const { history } = this.props
    return <PIComp styles={styles} history={history} useRouter={true} />
  }
}

const styles = {
  container: {
    maxWidth: 1200,
    height: 100,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'acumin pro, sans-serif',
    textTransform: 'uppercase',
    fontWeight: 300,
    color: '#B3ACA6',
  },
  content: {},
  links: {
    color: '#B3ACA6',
  },
}

export default withRouter(PathIndicator)
