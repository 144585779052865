import React, { Component } from 'react'
import '../index.css'
import loadingAnimation from '../assets/Loading_Animation.gif'

class SplashScreen extends Component {
  render() {
    return (
      <div
        {...this.props}
        style={{ ...styles.container, ...this.props.styles }}>
        <img style={styles.image} src={loadingAnimation} />
      </div>
    )
  }
}

SplashScreen.defaultProps = {
  styles: {},
}

const styles = {
  container: {
    backgroundColor: 'rgb(2, 30, 39)',
    position: 'fixed',
    top: 0,
    height: 'calc(var(--vh, 1vh) * 100)',
    width: '100%',
  },
  image: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    width: 100,
  },
}

export default SplashScreen
