import React from 'react'
import { Blurb as BlurbComp } from './common'
import Reveal from 'react-reveal'
import '../animations.css'

const ImageBlurb = (props) => {
  const useBackgroundImage = !!props?.gql?.backgroundImage
  return (
    <div style={{...styles.container, backgroundColor: '#404144', backgroundImage: useBackgroundImage ? `url(${props?.gql?.backgroundImage})` : null}}>
      <Reveal effect="fadeInUp-Blurb" duration={1000}>
        <BlurbComp
          styles={styles.blurb}
          {...props}
          content={props.gql}
        />
      </Reveal>
    </div>
  )
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 15,
    paddingBottom: 15,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  image: {
  },
  blurb: {
    title: {
      color: 'white',
      fontWeight: 700,
      lineHeight: 1.2,
      fontSize: 35,
    },
    text: {
      color: '#EDE8E4',
      fontSize: 16,
    },
  },
}

export default ImageBlurb
