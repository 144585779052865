import React, { Component } from 'react'
import Page from './Page'
import { gqlConnect } from 'react-connect-graphql-beta'
import { getComponent } from '../utils/ComponentFactory'

class NewsListPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sections: this.getSections(),
      header: this.getHeader(),
    }
  }
  getHeader = () => {
    return {
      styles: styles.header,
      title: 'News List',
    }
  }
  getSections = () => {
    const { gql } = this.props
    return gql.children.map((child, index) => {
      const Component = getComponent(child.id, child.typeHandle)
      return <Component key={index} />
    })
  }
  render() {
    const { sections, header } = this.state
    return (
      <Page
        styles={styles.page}
        header={header}
        sections={sections}
        showFooter={true}
        showContactForm={true}
      />
    )
  }
}

const styles = {
  page: {},
  header: {
    container: {
      height: 650,
      backgroundColor: '#021E27',
    },
    title: {
      backgroundColor: 'rgba(0,0,0,0)',
      fontSize: 65,
    },
    scroll: {
      display: 'none',
    },
  },
}

export default gqlConnect('newsPage')(NewsListPage)
