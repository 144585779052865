import React, { Component } from 'react'
import deepmerge from 'deepmerge'
import { withSizes } from './common'

class ShareButton extends Component {
  state = {
    hovered: false,
  }

  onHover = () => {
    this.setState({
      hovered: true,
    })
  }

  leaveHover = () => {
    this.setState({
      hovered: false,
    })
  }

  onClick = () => {
    const { hovered } = this.state
    this.setState({
      hovered: !hovered,
    })
  }

  onClickLink = () => {
    this.setState({ hovered: false })
  }

  render() {
    const { onHover, leaveHover, onClickLink, onClick } = this
    const { hovered } = this.state
    const { getStyle } = this.props

    return (
      <div
        onMouseLeave={() => leaveHover()}
        onMouseEnter={() => onHover()}
        onClick={() => onClick()}
        style={
          hovered
            ? deepmerge(
                getStyle(styles.container),
                getStyle(styles.container.hovered),
              )
            : getStyle(styles.container)
        }>
        <div
          style={
            hovered
              ? deepmerge(
                  getStyle(styles.share),
                  getStyle(styles.share.hovered),
                )
              : getStyle(styles.share)
          }>
          {hovered ? (
            <React.Fragment>
              <a
                onClick={() => onClickLink()}
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2F${window.location.host + window.location.pathname}%2F&amp;src=sdkpreparse`}
                className="fb-xfbml-parse-ignore">
                <img
                  alt={'facebook'}
                  style={getStyle(styles.social)}
                  src={require('../assets/Facebook_dark.png')}
                />
              </a>
              <a
                onClick={() => onClickLink()}
                target="_blank"
                rel="noopener noreferrer"
                href={
                  `https://www.linkedin.com/sharing/share-offsite/?url=${window.location.host + window.location.pathname}`
                }>
                <img
                  alt={'linkedin'}
                  style={getStyle(styles.social)}
                  src={require('../assets/LinkedIn.png')}
                />
              </a>
            </React.Fragment>
          ) : (
            'SHARE'
          )}
        </div>
        <img
          style={
            hovered
              ? deepmerge(
                  getStyle(styles.toggle),
                  getStyle(styles.toggle.hovered),
                )
              : getStyle(styles.toggle)
          }
          src={require('../assets/Plus.png')}
          alt={'toggle'}
        />
      </div>
    )
  }
}

const styles = {
  container: {
    zIndex: 100000,
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: 50,
    height: 132,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: 'height 0.15s',
    md: {
      backgroundColor: 'rgba(86, 42, 49, 0.75)',
      backdropFilter: 'blur(35px)',
      WebkitBackdropFilter: 'blur(35px)',
    },
    hovered: {
      height: 135,
    },
  },
  share: {
    position: 'absolute',
    fontSize: 25,
    writingMode: 'vertical-rl',
    color: '#B3ACA6',
    fontFamily: 'acumin-pro-condensed, sans-serif',
    fontWeight: 500,
    letterSpacing: 2.5,
    top: 15,
    left: 8,
    hovered: {
      left: 12,
      writingMode: 'unset',
    },
    md: {
      color: 'white',
    },
    cursor: 'default',
  },
  toggle: {
    bottom: 15,
    position: 'absolute',
    width: 22,
    height: 22,
    transition: 'transform 0.15s',
    hovered: {
      transform: 'rotate(45deg)',
    },
    md: {
      filter: 'brightness(0) invert(1)',
    },
  },
  social: {
    height: 25,
    width: 25,
    marginBottom: 18,
    cursor: 'pointer',
    md: {
      filter: 'brightness(0) invert(1)',
    },
  },
}

export default withSizes(ShareButton)
