import React, { Component } from 'react'
import { TileGrid as TileGridComp } from './common'
import TileBodyImage from './TileBodyImage'
import TileBodyIcon from './TileBodyIcon'
import { withRouter } from 'react-router-dom'

class TileGrid extends Component {
  render() {
    const { history, useGql, tiles, type } = this.props

    const _type = useGql ? this.props.gql.type : type
    const _tiles = useGql ? this.props.gql.tiles : tiles

    const TileBody = _type === 'icon' ? TileBodyIcon : TileBodyImage

    return (
      <TileGridComp
        styles={styles(_tiles.length, this.props.gql?.numCols)}
        tiles={_tiles.map((tile) => {
          return {
            subtitle: tile.text,
            renderBody: () => {
              return (
                <TileBody
                  history={history}
                  img={tile.img}
                  txt={tile.title}
                  url={tile.url}
                  pageEntry={tile.pageEntry}
                />
              )
            },
          }
        })}
      />
    )
  }
}

TileGrid.defaultProps = {
  useGql: true,
}

const styles = (numItems, numCols) => {
  return {
    container: {
      backgroundColor: 'rgba(0,0,0,0)',
      width: '100%',
      maxWidth: 1200,
      marginTop: 35,
      display: 'grid',
      justifyItems: 'center',
      gridTemplateColumns: `repeat(${numCols}, 1fr)`,
      gridRowGap: 50,
      gridColumnGap: 50,
      lg: {
        gridColumnGap: 35,
        gridTemplateColumns:
          numItems < 2 ? `repeat(${numItems}, 1fr)` : 'repeat(2, 1fr)',
      },
      sm: {
        gridTemplateColumns: 'auto',
      },
    },
    tileWrapper: {
      width: '100%',
      backgroundColor: 'transparent',
      sm: {
        width: '100%',
      },
    },
    tileBodyWrapper: {
      backgroundColor: '#DCD3CB',
      color: '#FFFFFF',
      // height: 275,
      // width: 275,
      position: 'relative',
      paddingTop: '100%',
      sm: {
        width: '100%',
        position: 'relative',
        paddingTop: '100%',
      },
    },
    tileSubtitle: {
      marginTop: 20,
      fontSize: 17,
      color: '#562A31',
    },
  }
}

export default withRouter(TileGrid)
