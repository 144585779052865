import React from 'react'
import {withSizes} from './common'
import Reveal from "react-reveal/Reveal";

const Process = ({ gql, getStyle, lg, md, sm, xs }) => {
  const { data } = gql
  const process = data?.process
  const processBackgroundColor = data?.processBackgroundColor[0]?.colorValue

  const processGap = 50
  const containerHeight = 'auto'
  const containerMarginBottom = processGap
  const containerMarginTop =processGap

  const renderProcessItem = (itemData, index) => {
    const processMarginTop = index === 0 ? (-1 * processGap) : processGap / 2
    const processMarginBottom = index === process?.length - 1 ? (-1 * processGap) : processGap / 2
    return (
      <div style={{...getStyle(styles.processContainer), marginTop: processMarginTop, marginBottom: processMarginBottom}}>
        {!sm && <div style={getStyle(styles.processNumberWrapper)}>
          <div style={getStyle(styles.processNumberText)}>
            {index + 1}
          </div>
        </div>}
        <div style={getStyle(styles.iconWrapper)}>
          <img src={itemData.icon[0]?.url} style={getStyle(styles.processIcon)} />
        </div>
        <div style={getStyle(styles.processCopyWrapper)}>
          <div style={getStyle(styles.mdBottomWrapper)}>
            {sm &&
            <div style={getStyle(styles.processNumberWrapper)}>
              <div style={getStyle(styles.processNumberText)}>
                {index + 1}
              </div>
            </div>
            }
            <Reveal effect="fadeInUp-Card" duration={1000}>
            <div style={getStyle(styles.processTitle)}>
              {itemData.processItemTitle}
            </div>
            </Reveal>
          </div>
          <Reveal effect="fadeInUp-Card" duration={1000}>
          <div style={getStyle(styles.processText)}>
            {itemData.text}
          </div>
          </Reveal>

        </div>
      </div>
    )
  }

  return (
    <div style={{...getStyle(styles.container), backgroundColor: processBackgroundColor, height: containerHeight, marginTop: containerMarginTop, marginBottom: containerMarginBottom}}>
      <>
        {process?.map((p, i) => renderProcessItem(p, i))}
      </>
      <div style={styles.line1}/>
      <div style={styles.line2} />
      <div style={styles.line3} />
    </div>
  )
}

const styles = {
  container: {
    width: '100%',
    paddingLeft: 50,
    paddingRight: 50,
    position: 'relative',
    maxWidth: 1200,
    paddingTop: 0,
    paddingBottom: 0,
    sm: {
      paddingLeft: 25,
      paddingRight: 25,
    },
  },
  line1: {
    width: 1,
    backgroundColor: '#7B7773',
    position: 'absolute',
    left: 100,
    top: 0,
    bottom: 0,
    sm: {
      left: 50,
    },
  },
  line2: {
    width: 1,
    backgroundColor: '#7B7773',
    position: 'absolute',
    left: '50%',
    top: 0,
    bottom: 0,
  },
  line3: {
    width: 1,
    backgroundColor: '#7B7773',
    position: 'absolute',
    right: 100,
    top: 0,
    bottom: 0,
    sm: {
      right: 50,
    },
  },
  processContainer: {
    width: '100%',
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: '#7B7773',
    borderStyle: 'solid',
    position: 'relative',
    padding: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
    flexDirection: 'row',
    md: {
      padding: 25,
    },
    sm: {
      flexDirection: 'column',
    }
  },
  processNumberWrapper: {
    display: 'flex',
    alignSelf: 'flex-start',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: '100%',
    sm: {
      marginRight: 30,
    },
  },
  processNumberText: {
    backgroundColor: '#872C3C',
    width: 70,
    height: 70,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
    paddingBottom: 23,
    color: 'white',
    fontSize: 50,
    md: {
      fontSize: 43,
      width: 60,
      height: 60,
    },
    xs: {
      fontSize: 35,
      width: 50,
      height: 50,
    },
  },
  iconWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  processIcon: {
    height: 150,
    md: {
      height: 125,
    },
    xs: {
      height: 100,
    },
  },
  processCopyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 75,
    flex: 3,
    sm: {
      marginLeft: 0,
      flex: 'unset',
    },
  },
  processTitle: {
    fontFamily: 'acumin pro, sans-serif',
    fontWeight: 300,
    color: '#862C3C',
    fontSize: 35,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    md: {
      fontSize: 30,
    },
    sm: {
      marginBottom: 0,
    },
    xs: {
      fontSize: 25,
    },
  },
  processText: {
    color: '#7D7773',
    fontSize: 16,
    fontFamily: 'acumin pro, sans-serif',
    md: {
      fontSize: 18,
    },
    xs: {
      fontSize: 16,
    },
  },
  mdBottomWrapper: {
    display: 'flex',
    marginTop: 0,
    marginBottom: 0,
    sm: {
      marginTop: 45,
      marginBottom: 20,
    },
    xs: {
      marginTop: 15,
    },
  },
}

export default withSizes(Process)