import React, { Component } from 'react'
import { Button } from './common'
import { gqlConnect } from 'react-connect-graphql-beta'
import { CardList } from './index'
import Spinner from 'react-spinkit'
import { connect } from 'react-redux'
import {setCanLoadMore} from '../redux/actions/feed'

class Feed extends Component {
  constructor(props) {
    super(props)

    this.state = {
      numItems: 0,
      buttonHovered: false,
      cardList: [],
      isLoading: false,
      reachEnd: false,
    }
  }

  onEndLoad = () => {
    this.setState({
      isLoading: false,
    })
  }

  onReachEnd = () => {
    this.setState({
      reachEnd: true,
    })
  }

  componentDidMount() {
    const { getCardList } = this

    getCardList()
  }
  getCardList = () => {
    const { gql, useGql, items, itemLimit, setCanLoadMore } = this.props
    const { numItems, cardList } = this.state
    const { onEndLoad, onReachEnd, leaveHoverButton } = this

    let _items = items

    if (numItems >= _items?.length) {
      this.setState({
        reachEnd: true,
      })
      return
    }

    leaveHoverButton()

    let lists = cardList

    let List, itemsPerPage, channelHandle, category
    if (useGql) {
      itemsPerPage = gql.itemsPerPage
      channelHandle = gql.channelHandle
      category = gql.category

      this.setState({
        isLoading: true,
      })

      List = gqlConnect('posts', {
        handle: channelHandle,
        offset: numItems,
        limit: channelHandle === 'services' ? null : itemsPerPage + 1,
      })(CardList)
    } else {
      itemsPerPage = itemLimit
      List = CardList

      if (_items?.length <= itemLimit) {
        setCanLoadMore(false)
      } else {
        setCanLoadMore(true)
        _items = _items.slice(numItems, numItems + itemsPerPage)
      }
    }

    lists.push(
      <List
        onReachEnd={onReachEnd}
        onEndLoad={onEndLoad}
        category={category}
        showDate={channelHandle === 'news' || gql?.showDate}
        items={useGql ? null : _items}
        useGql={useGql}
        limit={channelHandle === 'services' ? itemsPerPage : null}
        itemsPerPage={itemsPerPage}
      />,
    )
    this.setState({
      numItems: numItems + itemsPerPage,
      cardList: lists,
    })

    return (
      <List onReachEnd={onReachEnd} onEndLoad={onEndLoad} category={category} />
    )
  }
  hoverButton = () => {
    this.setState({
      buttonHovered: true,
    })
  }
  leaveHoverButton = () => {
    this.setState({
      buttonHovered: false,
    })
  }
  render() {
    const {
      cardList,
      buttonHovered,
      isLoading,
      reachEnd,
      numItems,
    } = this.state
    const { canLoadMore } = this.props
    const { getCardList, hoverButton, leaveHoverButton } = this

    return (
      <div style={styles.container}>
        <div style={styles.cardListWrapper}>
          {cardList.map((c, index) => {
            return <React.Fragment key={index}>{c}</React.Fragment>
          })}
        </div>
        <div style={styles.buttonWrapper}>
          {reachEnd ? (
            numItems <= 0 ? (
              'No results'
            ) : (
              'No more results'
            )
          ) : isLoading ? (
            <Spinner name={'circle'} color={'#E86956'} />
          ) : (
            canLoadMore && <Button
              id="loadMoreButton"
              handleHover={hoverButton}
              leaveHover={leaveHoverButton}
              onClick={getCardList}
              styles={styles.button}>
              Load More
              <img
                src={
                  buttonHovered
                    ? require('../assets/ReadMore_hover.png')
                    : require('../assets/ReadMore.png')
                }
                alt="Read More"
                style={{
                  height: 17,
                  width: 17,
                  marginLeft: 10,
                  marginBottom: 2,
                  transform: 'rotate(90deg)',
                }}
              />
            </Button>
          )}
        </div>
      </div>
    )
  }
}

Feed.defaultProps = {
  useGql: true,
}

const styles = {
  container: {},
  cardListWrapper: {},
  cardList: {},
  buttonWrapper: {
    height: 50,
    color: '#E86956',
    fontSize: 20,
    fontStyle: 'italic',
    fontFamily: 'capitolina, serif',
  },
  button: {
    boxShadow: 'none',
    outline: 'none',
    borderWidth: 0,
    color: '#E86956',
    fontSize: 30,
    fontStyle: 'italic',
    fontFamily: 'capitolina, serif',
    backgroundColor: '#FFFFFF',
    padding: 0,
    transition: 'none',
    hovered: {
      borderWidth: 0,
      backgroundColor: '#FFFFFF',
      color: '#852D3D',
    },
  },
}

const mapStateToProps = state => {
  return {
    canLoadMore: state.feed.canLoadMore,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCanLoadMore: canLoad => dispatch(setCanLoadMore(canLoad)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Feed)
