import config from '../config'

export const send = async (options) => {
  const { from, to, name, message, email, subject, content } = options

  let isSuccessful = false
  await fetch(`${config.atcompApi.url}/email/send`, {
    method: 'POST',
    body: JSON.stringify({
      from: from,
      to: to,
      name: name,
      message: message,
      email: email,
      subject: subject,
      content: content,
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.status === 'success') {
        isSuccessful = true
      } else if (response.status === 'fail') {
        isSuccessful = false
      }
    })

  return {
    isSuccessful: isSuccessful,
  }
}
