export const getCookies = () => {
  const cookies = document.cookie.split(';')
  const shoppingCartCookies = cookies.filter((cookie) => {
    return cookie.includes('atcShoppingCart')
  })
  if (shoppingCartCookies.length > 0) {
    return JSON.parse(shoppingCartCookies[0].replace('atcShoppingCart=', ''))
  } else {
    return []
  }
}

export const setCookies = (items) => {
  document.cookie = `atcShoppingCart=${JSON.stringify(items)}`
}

export const updateQty = (items, e, index) => {
  let _items = items

  _items[index].quantity = e

  return {
    type: 'UPDATE_ITEMS',
    payload: _items,
  }
}

export const removeItem = (items, index) => {
  items.splice(index, 1)
  return {
    type: 'UPDATE_ITEMS',
    payload: items,
  }
}

export const getSubtotal = (items) => {
  let subtotal = 0.0
  items.forEach((item) => {
    subtotal += item.price * item.quantity
  })

  return subtotal.toFixed(2)
}
