import React, { Component } from 'react'
import { Button, withSizes } from './common'
import { Input } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import '../index.css'

class SearchBar extends Component {
  state = {
    inputValue: this.props.searchText,
  }
  handleKeyPress = (key) => {
    const { submitSearch } = this

    if (key.charCode === 13) {
      submitSearch()
    } else {
      this.setState({
        inputValue: key.target.value,
      })
    }
  }
  submitSearch = () => {
    const { inputValue } = this.state
    const { history } = this.props

    history.push({
      pathname: '/search',
      search: `?q=${inputValue}`,
    })
  }
  render() {
    const { getStyle } = this.props
    const { inputValue } = this.state
    const { handleKeyPress, submitSearch } = this

    return (
      <div style={getStyle(styles.container)}>
        <Input
          id={'searchInput'}
          style={getStyle(styles.input)}
          name={'search'}
          onChange={(e) => handleKeyPress(e)}
          onKeyPress={(e) => handleKeyPress(e)}
          value={inputValue}
          type={'text'}
        />
        <Button onClick={() => submitSearch()} styles={styles.button}>
          SEARCH
        </Button>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    maxWidth: 1200,
    marginBottom: 35,
    md: {
      maxWidth: 800,
    },
  },
  input: {
    marginRight: 50,
    marginLeft: 'auto',
    maxWidth: 750,
    borderRadius: 0,
    outline: 'none',
    boxShadow: 'none',
    sm: {
      marginRight: 25,
    },
  },
  button: {
    marginRight: 'auto',
    backgroundColor: '#862C3C',
    borderWidth: 0,
    color: '#FFFFFF',
    borderRadius: 0,
    fontFamily: 'acumin-pro-condensed, sans-serif',
    letterSpacing: 1.25,
    hovered: {
      backgroundColor: '#E86956',
      borderWidth: 0,
      color: '#FFFFFF',
    },
  },
}

export default withRouter(withSizes(SearchBar))
