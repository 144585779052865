import React, { Component } from 'react'
import { Blurb as BlurbComp } from './common'
import { withRouter } from 'react-router-dom'
import Reveal from 'react-reveal'
import '../animations.css'

class Blurb extends Component {
  render() {
    const { useGql, content: _content, history } = this.props

    const isContact = history.location.pathname === '/contact'
    const content = useGql ? this.props.gql : _content
    return (
      <Reveal effect="fadeInUp-Blurb" duration={1000}>
        <BlurbComp
          styles={{
            ...styles.blurb,
            container: {
              marginLeft: isContact ? 'auto' : 0,
              marginRight: isContact ? 'auto' : 0,
              marginTop: 50,
              marginBottom: content?.heading ? 50 : 0,
            },
          }}
          {...this.props}
          content={content}
        />
      </Reveal>
    )
  }
}

const styles = {
  blurb: {
    title: {
      color: '#852D3D',
      fontWeight: 700,
      lineHeight: 1.2,
    },
    text: {
      color: '#7D7773',
    },
  },
}

Blurb.defaultProps = {
  useGql: true,
}

export default withRouter(Blurb)
