import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import { gqlConnect } from 'react-connect-graphql-beta'
import { getPost } from '../utils/ComponentFactory'
import NotFound from './NotFound'

class ChannelRoutes extends Component {
  state = {
    homepage: null,
    routes: [],
  }

  getRoutes = () => {
    const { data } = this.props.gql
    let routes = []
    let paths = []
    data.globalSet.redirects.forEach(redirect => {
      let pathname = window.location.pathname

      const oldUri = redirect.oldUri[0] === '/' ? redirect.oldUri : '/' + redirect.oldUri

      if(pathname === oldUri) {
        window.location.href = '/' + redirect.newUri
      }
    })
    data.entries.forEach((entry, index) => {
      const uriPieces = entry.uri.split('/')
      uriPieces.pop()
      let path = ''
      uriPieces.forEach((piece) => {
        path += '/' + piece
      })

      if (!paths.includes(path)) {
        routes.push(
          <Route
            key={index}
            exact
            path={`${path}/*`}
            component={getPost(entry.sectionHandle)}
          />,
        )
      }

      paths.push(path)
    })

    return routes
  }

  componentDidUpdate(prevProps) {
    const { loading } = this.props.gql
    if (!loading && loading !== prevProps.gql.loading) {
      this.setState({
        routes: this.getRoutes(),
      })
    }
  }

  componentDidMount() {
    const { loading } = this.props.gql
    let _routes = this.getRoutes()
    _routes.push(<Route component={NotFound} />)

    if (!loading) {
      this.setState({
        routes: _routes,
      })
    }
  }

  render() {
    const { routes } = this.state
    return <Switch>{routes}</Switch>
  }
}

export default gqlConnect('channelRoutes')(ChannelRoutes)
