import React, { Component } from 'react'
import { MegaMenu as MegaMenuComp } from './common'
import deepmerge from 'deepmerge'
import { getFeed } from '../utils/ParseNavigationData'
import { withRouter } from 'react-router-dom'

class MegaMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      columns: getFeed(props.gql.data, props.entry),
    }
  }

  render() {
    const { dropdownMenuStyles } = this
    const { history } = this.props
    const { columns } = this.state

    let styles =
      columns.length > 1
        ? deepmerge(dropdownMenuStyles, dropdownMenuStyles.services)
        : deepmerge(dropdownMenuStyles, dropdownMenuStyles.markets)

    columns.forEach((c) => {
      styles.columns.push({})
    })

    styles.columns[styles.columns.length - 1] = {
      link: {
        marginRight: styles.columns.length > 1 ? 20 : 0,
      },
    }

    return (
      <React.Fragment>
        <div style={styles.keepFocus}></div>
        <MegaMenuComp
          useRouter={true}
          history={history}
          styles={styles}
          columns={columns}
          // onClickItem={() => hideDropdown(null, null, false)}
        />
      </React.Fragment>
    )
  }

  dropdownMenuStyles = {
    container: {
      backgroundColor: '#EDE8E4',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      position: 'absolute',
      top: 5,
      padding: 20,
      height: 'auto',
      width: 'auto',
      boxShadow: '5px 5px #EA6852',
    },
    defaultColumn: {
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: 0,
    },
    keepFocus: {
      backgroundColor: 'rgba(0,0,0,0)',
      width: '100%',
      height: 10,
      position: 'absolute',
      top: -5,
      left: 0,
    },
    defaultHeader: {
      fontSize: 18,
      textTransform: 'uppercase',
      fontFamily: 'acumin-pro-condensed',
      letterSpacing: 1.25,
      fontWeight: 'bold',
      color: '#852D3D',
      marginBottom: 10,
      width: '100%',
      borderBottomStyle: 'solid',
      borderBottomColor: '#7D7773',
      borderBottomWidth: 1,
      paddingBottom: 10,
    },
    defaultLink: {
      fontSize: 16,
      fontWeight: 'bold',
      color: '#562A31',
      padding: 2,
      marginRight: 50,
      whiteSpace: 'nowrap',
      hover: {
        color: '#E86956',
      },
    },
    markets: {
      container: {
        left: 0,
      },
      columns: [],
    },
    services: {
      container: {
        padding: 30,
        left: 0,
      },
      columns: [],
    },
  }
}

export default withRouter(MegaMenu)
