const initialState = {
  cardsRendered: [],
  canLoadMore: true,
}

const feed = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_RENDERED_CARD':
      let cards = state.cardsRendered
      cards.push(action.payload)
      return Object.assign({}, state, { cardsRendered: cards })
    case 'CLEAR_RENDERED_CARDS':
      return Object.assign({}, state, { cardsRendered: [] })
    case 'SET_CAN_LOAD_MORE':
      return Object.assign({}, state, { canLoadMore: action.payload })
    default:
      return state
  }
}

export default feed
