import React, { Component } from 'react'
import { Container  } from 'reactstrap'
import mergeStyle from './utils/StyleMerge'
import withSizes from './utils/Sizes'
import Slideshow from './Slideshow'
import Reveal from 'react-reveal'
import './animations.css'
import {Button} from "./index";
import { withRouter } from 'react-router-dom'

class Header extends Component {
  onClickButton = (button) => {
    const { history } = this.props

    if(button?.buttonUrl) {
      window.open(button?.buttonUrl, '_blank')
    } else if(button?.buttonEntry) {
      history.push(button.buttonEntry[0]?.uri)
    }
  }
  render() {
    let {
      styles,
      title,
      subTitle,
      titleImageUrl,
      backgroundAsset,
      video,
      getStyle,
      slideshow,
      scrollImg,
      buttons,
    } = this.props
    const { onClickButton } = this

    if (!styles.backgroundAsset.height) {
      styles.backgroundAsset.height = styles.container.height
    }

    return (
      <Container fluid style={styles.container}>
        {!slideshow &&
          <div style={getStyle(styles.text)}>
            {titleImageUrl && titleImageUrl !== '' ?
              <img style={styles.titleImage} src={titleImageUrl} alt={''}/>
              :
              Array.isArray(title) ?
                title.map((t, index) => {
                  return (<span key={index} style={getStyle(styles.title)}>{t}</span>)
                })
                :
                <Reveal effect="fadeInOut-Header" duration={1000}>
                  <div style={{...getStyle(styles.title), ...getStyle(styles.titleShadowWrapper)}}>
                    <div style={getStyle(styles.titleShadow)}>{title}</div>
                  </div>
                  <div style={getStyle(styles.title)}>{title}</div>
                </Reveal>
            }
            <Reveal effect="fadeInOut-Header" duration={1000}>
              <div style={styles.subTitle}>
                <span>{subTitle}</span>
              </div>
            </Reveal>
          </div>
        }
        {!slideshow && backgroundAsset && backgroundAsset !== {} ?
          backgroundAsset.mimeType && backgroundAsset.mimeType.split('/')[0] === 'video' ?
            <video style={styles.backgroundAsset} {...video}>
              <source src={backgroundAsset.url} type={backgroundAsset.mimeType}/>
            </video>
          : backgroundAsset.mimeType && backgroundAsset.mimeType.split('/')[0] === 'image' ?
            <img style={styles.backgroundAsset} src={backgroundAsset.url} alt={''}/>
          : null
        : null}
        {
          slideshow && slideshow !== {} ?
            <div style={styles.slideshow}>
              <Slideshow {...slideshow} buttons={buttons} styles={styles.slideshow} />
            </div>
            :
            null
        }
        {scrollImg !== undefined && <img style={styles.scroll} src={scrollImg} alt={'scrollImg'} />}
      </Container>
    )
  }
}

const defaultStyles = {
  container: {
    width: '100%',
    height: 315,
    textAlign: 'center',
    fontSize: 32,
    fontWeight: 500,
    color: 'white',
    padding: 0,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonWrapper: {
    display: 'flex',
    padding: 25,
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '60%',
    left: 0,
    right: 0,
    xs: {
      bottom: 130,
    },
  },
  button: {
    height: 60,
    backgroundColor: '#862C3C',
    borderWidth: 0,
    color: '#FFFFFF',
    width: 300,
    borderRadius: 0,
    fontFamily: 'acumin-pro-condensed, sans-serif',
    letterSpacing: 1.25,
    margin: 15,
    xs: {
      margin: 5,
    },
    hovered: {
      backgroundColor: '#E86956',
      borderWidth: 0,
      color: '#FFFFFF',
    },
  },
  backgroundAsset: {
    position: 'fixed',
    width: '100%',
    zIndex: -1,
    objectFit: 'cover',
    top: 0,
    left: 0,
    right: 0,
  },
  slideshow: {
    height: '100%',
    width: '100%',
  },
  text: {
    zIndex: 10000,
  },
  title: {
  },
  titleShadow: {
  },
  titleShadowWrapper: {
    position: 'absolute',
    zIndex: -1,
  },
  subTitle: {
    marginTop: 5,
    fontSize: 12,
  },
  titleImage: {

  },
  fadeImages: {

  },
  scroll: {
    height: 75,
    width: 75,
    position: 'absolute',
    zIndex: 1,
    bottom: 35,
    left: (0.5 * window.clientWidth) - 37,
  },
}

Header.defaultProps = {
  title: '',
  subTitle: '',
  backgroundAsset: {},
  titleImageUrl: '',
  video: {
    loop: true,
    muted: true,
    playsInline: true,
    autoPlay: true,
    poser: '',
  },
  useGradient: true,
}

export default mergeStyle(defaultStyles)(withRouter(withSizes(Header)))
