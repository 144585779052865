import React, { Component } from 'react'
import {
  Button,
  NavLink,
  withSizes,
} from './common'
import { withRouter } from 'react-router-dom'
import deepmerge from 'deepmerge'
import Reveal from 'react-reveal'

class ListItemBody extends Component {
  constructor(props) {
    super(props)

    this.state = {
      hovered: false,
    }
  }
  hoverButton = () => {
    this.setState({
      hovered: true,
    })
  }
  leaveHoverButton = () => {
    this.setState({
      hovered: false,
    })
  }
  render() {
    const {
      text,
      title,
      styles,
      align,
      sm,
      getStyle,
      image,
      hoverImage,
      date,
      history,
      itemUrl,
      pageEntry,
      uri,
      buttonText,
      onHoverItem,
      onLeaveHoverItem,
      index,
      showDate,
    } = this.props
    const { hoverButton, leaveHoverButton } = this
    const { hovered } = this.state

    const _align = sm ? 'left' : align

    const _date = new Date(date)
    const formattedDate = _date
      .toLocaleString('default', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      })
      .toUpperCase()

    const useRouter = !!pageEntry
    const url = pageEntry ? pageEntry : itemUrl ? itemUrl : uri

    return (
      <div style={getStyle(styles.container)} align={_align}>
        <Reveal effect="fadeInUp-Card" duration={1000}>
          <div style={getStyle(styles.title)}>{title}</div>
          <div style={getStyle(styles.dateWrapper)}>
            {date && (showDate || itemUrl?.includes('news/')) && <div style={getStyle(styles.date)}>{date && formattedDate}</div>}
            <div style={getStyle(styles.divider)} />
          </div>
          </Reveal>
          <Reveal effect="fadeInUp-Card" duration={1000}>
          <div style={getStyle(styles.text)}>{text}</div>
        </Reveal>
        <div>
          {(itemUrl || pageEntry || uri) && (
            <NavLink
              history={history}
              useRouter={true}
              onMouseEnter={() => onHoverItem(index)}
              onMouseLeave={() => onLeaveHoverItem(index)}
              item={{ url: url }}
              style={styles.navLink}>
              <Button
                handleHover={hoverButton}
                leaveHover={leaveHoverButton}
                styles={getStyle(styles.button)}>
                {buttonText || 'Read More'}
                <i
                  style={
                    hovered
                      ? deepmerge(styles.arrow, styles.arrow.hovered)
                      : styles.arrow
                  }
                />
              </Button>
            </NavLink>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(withSizes(ListItemBody))
