import { getCookies, setCookies, getSubtotal } from '../actions/shoppingCart'

const items = getCookies()

const initialState = {
  items: items,
  subtotal: getSubtotal(items),
  emptyCart: items.length <= 0,
}

const shoppingCart = (state = initialState, action) => {
  if (action.type === 'UPDATE_ITEMS') {
    const _subtotal = getSubtotal(action.payload)
    setCookies(action.payload)
    const newState = {
      items: action.payload,
      subtotal: _subtotal,
      emptyCart: action.payload.length <= 0,
    }
    return Object.assign({}, state, newState)
  } else {
    return state
  }
}

export default shoppingCart
