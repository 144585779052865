import React, { Component } from 'react'
import DropdownSearch from '../components/DropdownSearch'
import { NavBarAnimated, withSizes } from './common'
import '../index.css'
import { gqlConnect } from 'react-connect-graphql-beta'
import { withRouter } from 'react-router-dom'
import MegaMenu from './MegaMenu'
import { connect } from 'react-redux'

class NavBarDesktop extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hover: false,
      navItems: this.getNavigationItems(),
      initialItemLength: 0,
      navBarRef: null,
    }
  }

  componentDidMount() {
    this.setState({
      initialItemLength: this.state.navItems.length,
    })
  }

  findChannelHandles = (entry) => {
    let handles = []
    entry.children.forEach((e) => {
      const obj = e.children ? e.children[0] : e
      if (!handles.includes(obj.navChannelHandle)) {
        handles.push(obj.navChannelHandle)
      }
    })
    return handles
  }
  getNavigationItems = () => {
    const { data } = this.props
    const { findChannelHandles } = this
    return data.entries.map((e) => {
      let item
      const type = e.children.length > 0 ? 'dropdown' : 'link'

      item = {
        text: e.navLinkName ? e.navLinkName : e.title,
        url: e.navLinkPage[0]?.slug || e.navLinkUrl || null,
        type: type,
        useRouter: e.navLinkPage.length > 0,
      }
      if (type === 'dropdown') {
        const handles = findChannelHandles(e)
        const MegaMenuGQL = gqlConnect('navCategories', { handle: handles })(
          MegaMenu,
        )
        item.render = (hideDropdown) => {
          return <MegaMenuGQL hideDropdown={hideDropdown} entry={e} />
        }
      }
      return item
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.navBarRef !== this.state.navBarRef) {
      this.setState({
        navData: this.getAllNavData(),
      })
    }
  }

  getAllNavData = () => {
    const { navItems, initialItemLength, navBarRef } = this.state

    let items = navItems

    if (items.length <= initialItemLength) {
      items.push({
        text: '',
        url: null,
        type: 'dropdown',
        align: 'right',
        image: require('../assets/Search.svg'),
        imageStyles: {
          upper: {
            height: 35,
            width: 35,
            position: 'relative',
            opacity: 1,
            transition: 'opacity 0.15s',
          },
          lower: {
            height: 35,
            width: 35,
            position: 'absolute',
            opacity: 0,
            filter:
              'invert(51%) sepia(50%) saturate(644%) hue-rotate(319deg) brightness(92%) contrast(96%)',
            transition: 'opacity 0.15s',
          },
          hoverUpper: {
            opacity: 0,
          },
          hoverLower: {
            opacity: 1,
          },
          darkMode: {
            upper: {
              filter: 'invert(100%)',
            },
            lower: {},
          },
        },
        render: (display) => {
          return (
            <DropdownSearch
              navBarRef={navBarRef}
              styles={styles}
              display={display}
            />
          )
        },
      })
    }
    return {
      navigation: {
        brand: {
          url: '',
          image: {
            title: 'ATC Logo',
            src: require('../assets/ATCLogo_OnDark.png'),
            darkModeImg: require('../assets/ATCLogo.png'),
            scrolled: {
              title: 'ATC Logo Small',
              src: require('../assets/ATCLogo_Small.png'),
            },
          },
        },
        dropdownIcon: require('../assets/DropdownCaret.png'),
        dropdownScrolledIcon: require('../assets/DropdownCaretScrolled.png'),
        items: items,
      },
    }
  }

  setNavRef = (r) => {
    const { navBarRef } = this.state

    if (!navBarRef) {
      this.setState({
        navBarRef: r,
      })
    }
  }

  onBrandClick = (e) => {
    const { history } = this.props

    if (history.location.pathname !== '/') {
      e.preventDefault()
      history.push('/')
    }
  }

  render() {
    const { getStyle, history, hasGradient, darkMode } = this.props
    // const { navData } = this.state
    const { setNavRef, onBrandClick } = this

    const navData = this.getAllNavData()

    return (
      <NavBarAnimated
        onBrandClick={onBrandClick}
        transitionWaitTime={350}
        navBarRef={setNavRef}
        items={navData.navigation.items}
        brand={navData.navigation.brand}
        styles={getStyle(styles)}
        icon={navData.navigation.dropdownIcon}
        useCustomMegaMenu={false}
        scrolledDropdownIcon={navData.navigation.dropdownScrolledIcon}
        fixed={true}
        changeOnScroll={true}
        useRouter={true}
        history={history}
        useGradient={hasGradient}
        darkMode={!darkMode}
        darkModeImg={navData.navigation.brand.image.darkModeImg}
        scrollTrigger={100}
      />
    )
  }
}

const styles = {
  navbar: {
    backgroundColor: 'rgba(0,0,0,0)',
    height: 150,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 65,
    paddingRight: 35,
    paddingTop: 30,
    paddingBottom: 30,
  },
  brand: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    marginRight: 0,
  },
  brandImage: {
    small: {
      width: 53,
      height: 'auto',
    },
    large: {
      width: 376,
      height: 'auto',
    },
  },
  collapse: {
    flex: 2,
    borderWidth: 0,
    marginLeft: 0,
    paddingLeft: 0,
    marginBottom: 15,
    position: 'relative',
  },
  nav: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-evenly',
    height: 50,
    paddingTop: 5,
    borderLeftWidth: 0,
    paddingLeft: 0,
    maxWidth: 800,
  },
  itemBorder: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#E86956',
    width: '100%',
  },
  navItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingTop: 5,
  },
  navLink: {
    color: '#FFFFFF',
    fontSize: 18,
    fontFamily: 'acumin-pro-condensed',
    letterSpacing: 1.25,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    borderBottomWidth: 3,
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgba(0,0,0,0)',
    transition:
      'border-bottom-width 0.25s, border-bottom-color 0.25s, color 0.25s',
    hover: {
      color: '#E86956',
      borderBottomColor: '#E86956',
    },
    paddingLeft: 0,
    paddingRight: 0,
  },
  gradient: {
    background:
      'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)',
    opacity: 0.9,
  },
  toggle: {
    display: 'flex',
    paddingLeft: 0,
  },
  dropdownItem: {
    color: '#FFFFFF',
    fontSize: 18,
    fontFamily: 'acumin-pro-condensed',
    letterSpacing: 1.25,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    borderBottomWidth: 3,
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgba(0,0,0,0)',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 2,
    paddingTop: 5,
    transition:
      'border-bottom-width 0.25s, border-bottom-color 0.25s, color 0.25s',
    hover: {
      color: '#E86956',
      borderBottomColor: '#E86956',
    },
  },
  dropdownIcon: {
    height: 12,
    width: 15,
    marginLeft: 7,
    marginTop: 5,
    alignSelf: 'center',
    transform: '',
    transition: 'transform 0.1s',
    hover: {
      transform: 'rotate(180deg)',
    },
  },
  imageItems: {
    display: 'flex',
    paddingTop: 15,
    paddingLeft: 10,
    marginLeft: 'auto',
    width: 128,
    justifyContent: 'flex-end',
    scrolled: {
      marginRight: -40,
    },
  },
  divider: {
    width: 2,
    height: 30,
    marginLeft: 35,
    marginRight: 35,
    opacity: 0,
    backgroundColor: '#FFFFFF',
  },
  search: {
    container: {
      width: 300,
      height: 50,
      backgroundColor: '#EDE8E4',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: -16,
      right: 130,
      marginTop: 10,
      paddingTop: 0,
    },
    form: {
      width: '90%',
    },
    formGroup: {},
    input: {
      backgroundColor: 'rgba(0,0,0,0)',
      borderWidth: 0,
      width: '100%',
    },
    shadow: {
      backgroundColor: '#E86956',
      width: 300,
      height: 50,
      position: 'absolute',
      top: -1,
      right: 125,
    },
  },
  scrolled: {
    navbar: {
      height: 65,
      paddingLeft: 35,
      paddingRight: 35,
      backgroundColor: 'rgba(57,57,57,0.9)',
      backdropFilter: 'blur(8px)',
      WebkitBackdropFilter: 'blur(8px)',
    },
    collapse: {
      flex: 30,
    },
    nav: {
      justifyContent: 'flex-start',
    },
    navLink: {
      marginRight: 40,
    },
    navItem: {
      paddingTop: 6,
    },
    dropdownItem: {
      paddingTop: 6,
    },
    toggle: {
      marginRight: 40,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  darkMode: {
    navLink: {
      color: '#404144',
    },
    dropdownItem: {
      color: '#404144',
    },
  },
}

const mapStateToProps = (state) => {
  return {
    hasGradient: state.navBar.hasGradient,
    darkMode: state.navBar.darkMode,
  }
}

export default connect(mapStateToProps)(withRouter(withSizes(NavBarDesktop)))
