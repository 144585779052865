import React, { Component } from 'react'
import { ListItemBody } from './index'
import { ListPage, withSizes } from './common'
import ImageRowItem from './ImageRowItem'
import { connect } from 'react-redux'
import {addRenderedCard, setCanLoadMore} from '../redux/actions/feed'

class CardList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      _items: this.filterItems(),
      activeHoverIndex: -1,
    }
  }

  filterItems = () => {
    const { category, items, gql, useGql, cardsRendered, limit, itemsPerPage, setCanLoadMore } = this.props

    let _items = useGql ? gql.items : items
    if (category) {
      _items = _items.filter((i) => {
        return (i.category === category) && (!cardsRendered.includes(i.slug))
      })
    }

    if(useGql) {
      if (_items.length <= itemsPerPage) {
        setCanLoadMore(false)
      } else {
        setCanLoadMore(true)
      }
    }

    if(limit) {
      _items = _items.slice(0, limit)
    } else if (itemsPerPage) {
      _items = _items.slice(0, itemsPerPage)
    }

    return _items
  }

  componentDidMount() {
    const { onEndLoad, onReachEnd, addRenderedCard } = this.props
    const { _items } = this.state

    if (onEndLoad) {
      onEndLoad()
    }
    if (_items.length <= 0 && onReachEnd) {
      onReachEnd()
    }

    _items.forEach(item => {
      addRenderedCard(item.slug)
    })
  }

  onHoverItem = (index) => {
    this.setState({
      activeHoverIndex: index,
    })
  }

  onLeaveHoverItem = () => {
    this.setState({
      activeHoverIndex: -1,
    })
  }

  render() {
    const { getStyle, showDate } = this.props
    const { onHoverItem, onLeaveHoverItem } = this
    const { activeHoverIndex, _items } = this.state

    return (
      <ListPage
        styles={getStyle(styles.listPage)}
        list={{
          items: _items,
          renderItems: [
            (props, index) => (
              <ImageRowItem
                styles={
                  activeHoverIndex === index
                    ? {
                        ...getStyle(styles.imageRowItem),
                        image: {
                          transition: 'transform 2s cubic-bezier(0.19, 1, 0.22, 1)',
                          transform: 'scale(1.0)',
                        },
                      }
                    : getStyle(styles.imageRowItem)
                }
                imageHoverStyles={{ transform: 'scale(1.0)' }}
                stack={'sm'}
                alignImage={'right'}
                {...props}
                renderBody={(xs) => (
                  <ListItemBody
                    {...props}
                    align={'left'}
                    showDate={showDate}
                    index={index}
                    onHoverItem={onHoverItem}
                    onLeaveHoverItem={onLeaveHoverItem}
                    styles={styles.listItemBody}
                  />
                )}
              />
            ),
            (props, index) => (
              <ImageRowItem
                styles={
                  activeHoverIndex === index
                    ? {
                        ...getStyle(styles.imageRowItem),
                        image: {
                          transition: 'transform 2s cubic-bezier(0.19, 1, 0.22, 1)',
                          transform: 'scale(1.0)',
                        },
                      }
                    : getStyle(styles.imageRowItem)
                }
                imageHoverStyles={{ transform: 'scale(1.0)' }}
                stack={'sm'}
                alignImage={'left'}
                {...props}
                renderBody={(xs) => (
                  <ListItemBody
                    {...props}
                    align={'left'}
                    showDate={showDate}
                    index={index}
                    onHoverItem={onHoverItem}
                    onLeaveHoverItem={onLeaveHoverItem}
                    styles={styles.listItemBody}
                  />
                )}
              />
            ),
          ],
        }}
      />
    )
  }
}

CardList.defaultProps = {
  onLoadingSelf: true,
  useGql: true,
}

const styles = {
  listPage: {
    pageContainer: {
      maxWidth: 1200,
      width: '100%',
      backgroundColor: 'rgba(0,0,0,0)',
      display: 'flex',
      justifyContent: 'center',
    },
    contentContainer: {
      maxWidth: 1200,
      backgroundColor: 'rgba(0,0,0,0)',
    },
    contentSection: {
      backgroundColor: 'rgba(0,0,0,0)',
      flex: 1,
    },
  },
  imageRowItem: {
    container: {
      backgroundColor: '#FFFFFF',
      flex: 1,
      marginBottom: 50,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#B3ACA6',
      minHeight: 450,
      overflow: 'hidden',
    },
    image: {
      transform: 'scale(1.05)',
      transition: 'transform 2s cubic-bezier(0.19, 1, 0.22, 1)',
      xs: {
        height: '100%',
      },
    },
    imageColumn: {
      minHeight: 450,
      maxHeight: 'none',
      height: 'auto',
      padding: 0,
      //  paddingBottom: 2,
      width: '50%',
      xs: {
        height: 300,
      },
    },
    md: {
      container: {
        minHeight: 550,
      },
      imageColumn: {
        minHeight: 550,
      },
    },
    sm: {
      container: {
        minHeight: 0,
        maxHeight: 'none',
        height: 'auto',
      },
      imageColumn: {
        minHeight: 250,
      },
      image: {
        height: '100%',
      },
      row: {
        height: '100%',
      },
      bodyColumn: {
       // minHeight: 'none',
        height: 'auto',
      },
    },
    xs: {
      imageColumn: {
        minHeight: 300,
      },
      image: {
        height: '100%',
      },
      row: {
        height: '100%',
      },
    },
  },
  listItemBody: {
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingLeft: 32,
      paddingRight: 32,
      paddingTop: 35,
      paddingBottom: 75,
      sm: {
        paddingLeft: 20,
        paddingRight: 25,
        paddingTop: 10,
        // justifyContent: 'space-between',
        paddingBottom: 10,
      },
      xs: {},
    },
    arrow: {
      borderStyle: 'solid',
      borderColor: '#E86956',
      display: 'inline-block',
      borderWidth: '0 3px 3px 0',
      transform: 'rotate(-45deg)',
      padding: 4,
      transition: 'transform 0.25s',
      marginBottom: 2,
      marginLeft: 5,
      hovered: {
        borderColor: '#852D3D',
        transform: 'translateX(10px) rotate(-45deg)',
      },
    },
    title: {
      color: '#852D3D',
      fontSize: 45,
      fontWeight: 'bold',
      marginBottom: 35,
      lineHeight: 1.2,
      md: {},
      sm: {
        fontSize: 35,
        marginBottom: 10,
      },
    },
    dateWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 35,
      sm: {
        marginBottom: 20,
      },
    },
    date: {
      marginRight: 10,
      color: '#B3ACA6',
      letterSpacing: 2,
    },
    divider: {
      flex: 1,
      backgroundColor: '#B3ACA6',
      height: 1,
    },
    text: {
      color: '#7D7773',
      fontSize: 16,
      sm: {},
    },
    navLink: {
      padding: 0,
    },
    button: {
      borderWidth: 0,
      outline: 'none',
      boxShadow: 'none',
      color: '#E86956',
      fontSize: 25,
      fontStyle: 'italic',
      fontFamily: 'capitolina, serif',
      backgroundColor: '#FFFFFF',
      padding: 0,
      position: 'absolute',
      bottom: 35,
      transition: 'none',
      hovered: {
        borderWidth: 0,
        backgroundColor: '#FFFFFF',
        color: '#852D3D',
      },
      sm: {
        position: 'static',
        marginTop: 10,
      },
    },
  },
}


const mapStateToProps = state => {
  return {
    cardsRendered: state.feed.cardsRendered,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addRenderedCard: cards => dispatch(addRenderedCard(cards)),
    setCanLoadMore: canLoad => dispatch(setCanLoadMore(canLoad))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSizes(CardList))
