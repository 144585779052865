import React, { Component } from 'react'
import { Form, FormGroup, Input } from 'reactstrap'
import { withRouter, useLocation } from 'react-router-dom'

class DropdownSearch extends Component {
  state = {
    display: 'none',
  }
  componentDidUpdate(prevProps) {
    setTimeout(() => {
      if (document.getElementById('ddSearch') !== null) {
        document.getElementById('ddSearch').focus()
      }
    }, 0)
  }

  handleKeyPress = (key) => {
    const { history, navBarRef } = this.props

    if (key.charCode === 13) {
      history.push({
        pathname: '/search',
        search: `?q=${key.target.value}`,
      })
      key.target.value = ''
      navBarRef.hideImageItem()
    }
  }

  render() {
    const { styles } = this.props
    const { handleKeyPress } = this

    return (
      <div id={'ddSearchBox'}>
        <div style={styles.search.shadow}></div>
        <div style={styles.search.container}>
          <div onKeyPress={handleKeyPress} style={styles.search.form}>
            <div style={styles.search.formGroup}>
              <Input
                onChange={(e) => handleKeyPress(e)}
                type="text"
                name="search"
                id="ddSearch"
                placeholder="Search"
                style={styles.search.input}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(DropdownSearch)
