import React, { Component } from 'react'

class ImageSliderPrevArrow extends Component {
  state = {
    hovered: false,
  }

  onHover = () => {
    this.setState({
      hovered: true,
    })
  }


  onLeaveHover = () => {
    this.setState({
      hovered: false,
    })
  }

  render() {
    const { onHover, onLeaveHover } = this
    const { hovered } = this.state
    const { onClick } = this.props

    return (
      <div onClick={onClick} onMouseEnter={onHover} onMouseLeave={onLeaveHover} style={styles.container}>
        <i style={{
          ...styles.arrow,
          borderColor: hovered ? '#852D3D' : '#7D7773',
        }} />
      </div>
    )
  }
}

const styles = {
  container: {
    position: 'absolute',
    left: -40,
    top: 135,
    height: 115,
    width: 50,
    cursor: 'pointer',
    alignSelf: 'center',
    padding: 15,
  },
  arrow: {
    borderStyle: 'solid',
    borderColor: '#000000',
    borderWidth: '0 2px 2px 0',
    padding: 13,
    display: 'inline-block',
    transform: 'rotate(135deg)',
    transition: 'border-color 0.25s'
  },
}

export default ImageSliderPrevArrow
