import React from 'react'
import { addClassToHtmlTag } from '../utils/HTMLTags'

const Text = props => {
  let html = props.gql.data.pageTextBody
  html = addClassToHtmlTag(html, 'ul', 'articleUl')
  html = addClassToHtmlTag(html, 'ol', 'articleOl')
  html = addClassToHtmlTag(html, 'a', 'articleAnchor')

  return <div style={{...styles.container, textAlign: props.gql.data.bodyTextAlignment || 'left' }} dangerouslySetInnerHTML={{__html: html}} />
}

const styles = {
  container: {
    maxWidth: 1200,
    lineHeight: 2,
    color: '#404144',
    width: '100%'
  }
}

export default Text
