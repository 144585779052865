import React, { Component } from 'react'
import { Route, withRouter, Switch } from 'react-router-dom'
import ChannelRoutes from './ChannelRoutes'
import { gqlConnect } from 'react-connect-graphql-beta'
import { getHomepage, getPage, getSearchPage } from '../utils/ComponentFactory'
import PrivacyPolicy from './PrivacyPolicy'
import { setDarkMode } from '../redux/actions/navBar'
import { connect } from 'react-redux'
import { clearRenderedCards } from '../redux/actions/feed'
import NotFound from './NotFound'
import TermsConditions from './TermsConditions'
import Documentation from "./Documentation";
import DocumentationEntry from "./DocumentationEntry";

class Routes extends Component {
  state = {
    homepage: null,
    routes: [],
  }

  getRoutes = () => {
    const { slugs } = this.props.gql
    return slugs.map((slug, index) => {
      return (
        <Route key={index} exact path={`/${slug}`} component={getPage(slug)} />
      )
    })
  }

  componentDidUpdate(prevProps) {
    const { loading, location } = this.props
    if (!loading && loading !== prevProps.loading) {
      this.setState({
        homepage: getHomepage(),
        routes: this.getRoutes(),
      })
    }
    if (prevProps.location !== location) {
      this.onRouteChange()
    }
  }

  componentDidMount() {
    const { loading } = this.props
    if (!loading) {
      this.setState({
        homepage: getHomepage(),
        routes: this.getRoutes(),
      })
    }
  }

  onRouteChange = () => {
    this.props.clearRenderedCards()
    if (this.props.history.location !== this.props.location) {
      this.props.setDarkMode(true)
    }
  }

  render() {
    const { homepage, routes } = this.state
    const { location } = this.props
    return (
      <Switch>
        <Route exact path={'/'} component={homepage} />
        <Route
          path={'/search'}
          component={getSearchPage(location.search.replace('?q=', ''))}
        />
        <Route exact path={'/privacy-policy'} component={PrivacyPolicy} />
        <Route exact path={'/terms-conditions'} component={TermsConditions} />
        <Route exact path={'/documentation'} component={Documentation} />
        <Route path={'/documentation'} component={DocumentationEntry} />
        {routes}
        <ChannelRoutes />
      </Switch>
    )
  }
}

const mapDispatchToProps = {
  setDarkMode,
  clearRenderedCards,
}

export default gqlConnect('routes')(
  withRouter(connect(null, mapDispatchToProps)(Routes)),
)
