import React, {useState} from 'react'
import {withSizes} from './common'
import deepmerge from "deepmerge";
import Reveal from "react-reveal/Reveal";
import {useHistory} from "react-router-dom";


const Card  = ({ data, index, md, getStyle, total }) => {
  const [isHovered, setIsHovered] = useState(false)
  const history = useHistory()

  const onClick = (button) => {
    if(button?.buttonUrl) {
      window.open(button?.buttonUrl, '_blank')
    } else if(button?.buttonEntry) {
       history.push(button.buttonEntry)
    }
  }

  const renderCopy = () => {
    return (
      <Reveal effect="fadeInUp-Card" duration={1000}>
      <div style={getStyle(styles.copyWrapper)}>
        <div style={getStyle(styles.copyHeader)}>
          {data?.title}
        </div>
        <div style={getStyle(styles.copyText)}>
          {data?.text}
        </div>
        <div onClick={() => onClick({buttonEntry: data?.pageEntry, buttonUrl: data?.itemUrl})} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} style={styles.buttonWrapper}>
          <span style={isHovered ? getStyle(deepmerge(styles.button, styles.button.hovered)) : getStyle(styles.button)}>{data?.buttonText}</span>
          <span style={isHovered ? getStyle(deepmerge(styles.arrow, styles.arrow.hovered)) : getStyle(styles.arrow)} />
        </div>
      </div>
      </Reveal>
    )
  }
  const renderImage = () => {
    return (
      <div style={getStyle(styles.imageWrapper)}>
        <img src={data?.imageUrl} style={getStyle(styles.image)}/>
      </div>
    )
  }
  return (
    <div style={getStyle({...styles.container, marginTop: index === 0 ? 0 : 50, marginBottom: index === total - 1 ? 0 : 50})}>
      {md ? renderImage() : index % 2 === 0 ? renderCopy() : renderImage()}
      {!md && <div style={getStyle(styles.spacer)}/>}
      {md ? renderCopy() : index % 2 === 0 ? renderImage() : renderCopy()}
    </div>
  )
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    md: {
      flexDirection: 'column',
    },
  },
  copyWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
    flex: 1,
    md: {
      justifyContent: 'flex-start',
    },
  },
  copyHeader: {
    color: '#852D3D',
    fontSize: 35,
    fontWeight: 'bold',
    lineHeight: 1.2,
    marginBottom: 25,
    md: {
      marginBottom: 25,
    },
  },
  copyText: {
    color: '#7D7773',
    fontSize: 16,
  },
  imageWrapper: {
    flex: 1,
    marginBottom: 0,
    md: {
      marginBottom: 35,
    },
  },
  image: {
   height: '100%',
    width: '100%',
    objectFit: 'cover',
    md: {
      // height: 300,
    },
  },
  spacer: {
    width: 75,
    md: {
      width: 0,
    },
  },
  buttonWrapper: {
    cursor: 'pointer',
    marginTop: 35,
    md: {
      marginTop: 15,
    },
  },
  button: {
    color: '#E86956',
    fontSize: 25,
    fontStyle: 'italic',
    fontFamily: 'capitolina, serif',
    hovered: {
      color: '#852D3D',
    },
  },
  arrow: {
    borderStyle: 'solid',
    borderColor: '#E86956',
    display: 'inline-block',
    borderWidth: '0 3px 3px 0',
    transform: 'rotate(-45deg)',
    padding: 4,
    transition: 'transform 0.25s',
    marginBottom: 2,
    marginLeft: 5,
    hovered: {
      borderColor: '#852D3D',
      transform: 'translateX(10px) rotate(-45deg)',
    },
  },
}

export default withSizes(Card)
