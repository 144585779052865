import React, { Component } from 'react'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import deepmerge from 'deepmerge'
import { NavLink, withSizes } from './common'
import { withRouter } from 'react-router-dom'
import { monthNames } from '../utils/MonthNames'

class TimelineItem extends Component {
  render() {
    const { data, index, isOneColumn } = this.props

    const isRight = index % 2 === 0 && !isOneColumn
    const showDate = data.timelineItemDate !== null

    let dateObj
    let displayDate = ''
    let dateComponents = data.timelineItemDate?.split('-')
    if (dateComponents) {
      dateComponents[2] = dateComponents[2]?.slice(0, 2)

      dateObj = {
        month: monthNames[parseInt(dateComponents[1], 10) - 1],
        day: parseInt(dateComponents[2], 10),
        year: dateComponents[0],
      }

      switch (data.timelineDateFields) {
        case 'showOnlyYear':
          displayDate = dateObj.year
          break
        case 'showMonthDayAndYear':
          displayDate = `${dateObj.month} ${dateObj.day}, ${dateObj.year}`
          break
        default:
          displayDate = `${dateObj.month}, ${dateObj.year}`
          break
      }
    }
    return (
      <div
        style={{
          ...styles.item.container,
          alignItems: isRight ? 'flex-end' : 'flex-start',
          marginTop: showDate && displayDate !== '' ? -30 : 0,
        }}>
        {showDate && displayDate !== '' && (
          <div
            style={{
              ...styles.item.date,
              paddingLeft: isOneColumn ? 0 : 35,
            }}>
            {displayDate}
          </div>
        )}
        <div
          style={{
            ...styles.item.header,
            paddingLeft: isOneColumn ? 0 : 35,
            fontSize: isOneColumn ? 26 : 32,
            textAlign: isRight ? 'right' : 'left',
          }}>
          {data.timelineItemHeading}
        </div>
        <div
          className={'timeline-item-divider'}
          style={{
            ...styles.item.divider,
            width: '100%',
            marginLeft: isOneColumn ? -20 : 0,
          }}
        />
        <div
          style={{
            ...styles.item.body,
            textAlign: isRight ? 'right' : 'left',
            paddingLeft: isOneColumn ? 0 : 35,
          }}>
          {data.timelineItemBody}
        </div>
      </div>
    )
  }
}

class Timeline extends Component {
  state = {
    isOneColumn: false,
    hovered: false,
    activeHoverIndex: null,
  }

  hoverLink = (index) => {
    this.setState({
      hovered: true,
      activeHoverIndex: index,
    })
  }

  leaveHoverLink = (index) => {
    this.setState({
      hovered: false,
      activeHoverIndex: index,
    })
  }

  componentDidMount() {
    const { onResize } = this
    if (window.innerWidth < 992) {
      this.setState({
        isOneColumn: true,
      })
    }

    window.addEventListener('resize', onResize)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { alignCircle } = this
    alignCircle()
  }

  alignCircle = () => {
    const circles = document.getElementsByClassName(
      'vertical-timeline-element-icon',
    )
    const dividers = document.getElementsByClassName('timeline-item-divider')

    if (circles && dividers) {
      let index = 0
      for(let circle of circles) {
        const divider = dividers[index]
        const itemBody = document.getElementsByClassName('vertical-timeline-element')[index]

        const bodyTop = itemBody?.getBoundingClientRect().top
        const circleHeight = circle.getBoundingClientRect().height
        const dividerTop = divider.getBoundingClientRect().top

        circle.style.position = "absolute"
        circle.style.top = `${dividerTop - bodyTop - (circleHeight / 2)}px`

        index++
      }
    }
  }

  onResize = () => {
    const { isOneColumn } = this.state
    const { alignCircle } = this

    if (window.innerWidth < 992) {
      if (!isOneColumn) {
        this.setState({
          isOneColumn: true,
        })
      }
    } else {
      if (isOneColumn) {
        this.setState({
          isOneColumn: false,
        })
      }
    }

    alignCircle()
  }

  renderItemImage = (item, index) => {
    const { isOneColumn, hovered, activeHoverIndex } = this.state
    const { history } = this.props
    const { hoverLink, leaveHoverLink } = this

    const isRight = index % 2 === 0 && !isOneColumn

    const imageStyles = styles.images[index]?.imageWrapper
      ? deepmerge(styles, styles.images[index])
      : styles

    let imageSize = { height: 0, width: 0 }

    switch (item.imageSize) {
      case 'small':
        imageSize.height = 250
        imageSize.width = 250
        break
      case 'large':
        imageSize.height = 350
        imageSize.width = 350
        break
      default:
        imageSize.height = 300
        imageSize.width = 300
        break
    }

    if (isOneColumn) {
      imageSize = {
        height: 200,
        width: 200,
      }
    }

    const navLinkItem = {
      url:
        item.timelineLinkPage.length > 0
          ? item.timelineLinkPage[0].uri
          : item.timelineExternalUrl
          ? item.timelineExternalUrl
          : null,
    }

    const magnifyOnHover = true // navLinkItem.url !== null
    const _hovered = magnifyOnHover && hovered && index === activeHoverIndex

    return (
      <NavLink
        useRouter={item.timelineLinkPage.length > 0}
        history={history}
        item={navLinkItem}
        onMouseEnter={() => hoverLink(index)}
        onMouseLeave={() => leaveHoverLink(index)}
        style={{
          ...imageStyles.imageWrapper,
          marginTop: isOneColumn ? 20 : 0,
          height: imageSize.height,
          width: imageSize.width,
          float: isRight ? 'left' : 'right',
          transform: _hovered
            ? isOneColumn
              ? 'scale(1.05, 1.05)'
              : 'scale(1.1, 1.1)'
            : 'none',
          transition: 'transform 0.5s',
        }}>
        <img
          style={{
            ...imageStyles.imageWrapper.image,
            height: imageSize.height,
            width: imageSize.width,
            float: isRight ? 'left' : 'right',
            transform: _hovered ? 'scale(1.0, 1.0)' : 'scale(1.2, 1.2)',
            transition: 'transform 0.5s',
          }}
          src={
            item.timelineItemImage.length > 0
              ? item.timelineItemImage[0].url
              : null
          }
          alt={'img'}
        />
      </NavLink>
    )
  }
  render() {
    const { renderItemImage } = this
    const { isOneColumn } = this.state
    const { data } = this.props.gql

    return (
      <div
        style={{
          ...styles.container,
          marginTop: isOneColumn ? 45 : 75,
        }}>
        <div
          style={{
            ...styles.header,
            marginBottom: isOneColumn ? 25 : 75,
            fontSize: isOneColumn ? 45 : 60,
          }}>
          {data[0].timelineHeading}
        </div>
        <VerticalTimeline>
          {data[0].timelineItems.map((item, index) => {
            return (
              <VerticalTimelineElement
                key={index}
                date={renderItemImage(item, index)}
                iconStyle={styles.icon}>
                <TimelineItem
                  styles={styles}
                  data={item}
                  index={index}
                  isOneColumn={isOneColumn}
                />
              </VerticalTimelineElement>
            )
          })}
        </VerticalTimeline>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 1200,
  },
  header: {
    color: '#7D7773',
    fontFamily: 'Capitolina',
    fontWeight: 700,
    textAlign: 'center',
    maxWidth: 900,
  },
  item: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    date: {
      color: '#7D7773',
      fontFamily: 'acumin-pro, sans-serif',
      fontWeight: 600,
      fontSize: 19,
      letterSpacing: 1.25,
      textTransform: 'uppercase',
      paddingRight: 35,
    },
    header: {
      paddingRight: 35,
      marginBottom: 15,
      color: '#E86956',
      fontFamily: 'Capitolina',
      fontWeight: 500,
      fontStyle: 'italic',
      lineHeight: 1.25,
    },
    body: {
      paddingRight: 35,
      marginTop: 15,
      color: '#404144',
      fontSize: 20,
      fontFamily: 'acumin-pro, sans-serif',
    },
    divider: {
      height: 2,
      backgroundColor: '#B3ACA6',
      opacity: 0.5,
      marginTop: 8,
    },
  },
  imageWrapper: {
    padding: 0,
    borderRadius: '50%',
    overflow: 'hidden',
    image: {
      objectFit: 'cover',
    },
  },
  images: [],
  icon: {
    backgroundColor: '#862C3C',
    boxShadow: 'none',
    zIndex: 10000,
  },
}

export default withRouter(withSizes(Timeline))
