import React, { Component } from 'react'
import { NavBarMobile as NavBarComp } from './common'
import '../index.css'
import ServicesMobile from './ServicesMobile'
import MarketsMobile from './MarketsMobile'
import gsap, { Power2 } from 'gsap'
import { gqlConnect } from 'react-connect-graphql-beta'
import { withRouter } from 'react-router-dom'

class NavBarMobile extends Component {
  toggleCollapse = (open) => {
    const duration = 0.25
    const tl = gsap.timeline({
      smoothChildTiming: true,
      defaults: { duration: duration, ease: Power2.easeOut },
    })

    if (open) {
      document.getElementById('mobileCollapse').style.borderTopWidth = '5px'
      tl.to(
        '#mobileCollapse',
        {
          height: 'calc(100vh - 65px)',
          paddingTop: 25,
          paddingBottom: 25,
          marginTop: 4,
        },
        0,
      )
    } else {
      document.getElementById('mobileCollapse').style.borderTopWidth = '0px'
      tl.to(
        '#mobileCollapse',
        {
          height: 0,
          paddingTop: 0,
          paddingBottom: 0,
          marginTop: 0,
        },
        0,
      )
    }
  }

  findChannelHandles = (entry) => {
    let handles = []
    entry.children.forEach((e) => {
      const obj = e.children ? e.children[0] : e
      if (!handles.includes(obj.navChannelHandle)) {
        handles.push(obj.navChannelHandle)
      }
    })
    return handles
  }

  getNavigationItems = () => {
    const { data, history } = this.props
    const { findChannelHandles } = this

    return data.entries.map((e) => {
      let item
      const type = e.children.length > 0 ? 'dropdown' : 'link'
      const hasCategories = e.children[0]?.children !== undefined

      item = {
        text: e.navLinkName ? e.navLinkName : e.title,
        url: e.navLinkPage[0]?.slug || e.navLinkUrl || null,
        type: type,
        useRouter: e.navLinkPage.length > 0,
      }
      if (type === 'dropdown') {
        const handles = findChannelHandles(e)
        if (hasCategories) {
          const ServicesMobileGQL = gqlConnect('navCategories', {
            handle: handles,
          })(ServicesMobile)
          item.menu = (d, toggle) => {
            return (
              <ServicesMobileGQL
                history={history}
                toggle={toggle}
                entry={e}
                display={d}
              />
            )
          }
        } else {
          const MarketsMobileGQL = gqlConnect('navCategories', {
            handle: handles,
          })(MarketsMobile)
          item.menu = (d, toggle) => {
            return (
              <MarketsMobileGQL
                toggle={toggle}
                history={history}
                entry={e}
                display={d}
              />
            )
          }
        }
      }
      return item
    })
  }

  render() {
    const { getNavigationItems } = this
    const { history } = this.props

    const navItems = getNavigationItems()
    const data = getAllNavData(navItems)

    return (
      <NavBarComp
        items={data.navigation.items}
        brand={data.navigation.brand}
        styles={styles}
        icon={data.navigation.dropdownIcon}
        fixed={true}
        openToggleIcon={data.navigation.openToggleIcon}
        toggleCollapse={this.toggleCollapse}
        closeToggleIcon={data.navigation.closeToggleIcon}
        useRouter={true}
        history={history}
      />
    )
  }
}

const getAllNavData = (items) => {
  items.push({
    text: 'Search',
    url: 'search',
    type: 'link',
    useRouter: true,
  })
  return {
    navigation: {
      brand: {
        url: '#/',
        image: {
          title: 'ATC Logo',
          src: require('../assets/ATCLogo_mobile.png'),
        },
      },
      dropdownIcon: require('../assets/DropdownCaret.png'),
      openToggleIcon: require('../assets/toggleOpen.png'),
      closeToggleIcon: require('../assets/toggleClose.png'),
      items: items,
    },
  }
}

const styles = {
  container: {
    backgroundColor: 'rgba(57,57,57,0.9)',
    backdropFilter: 'blur(8px)',
    WebkitBackdropFilter: 'blur(8px)',
    minWidth: 320,
    height: 65,
  },
  navbar: {
    padding: 0,
    overflow: 'hidden',
  },
  brand: {},
  brandImage: {
    marginLeft: 30,
    marginTop: 3,
    width: 300,
    height: 48,
    xxs: {
      width: 245,
      height: 37,
      marginLeft: 15,
    },
  },
  toggler: {
    height: 25,
    width: 25,
    marginRight: 30,
    xxs: {
      marginRight: 15,
    },
  },
  collapse: {
    overflow: 'hidden',
    backgroundColor: '#EDE8E4',
    borderTopWidth: 0,
    borderTopStyle: 'solid',
    borderTopColor: '#E86956',
    paddingTop: 0,
    paddingBottom: 0,
    marginTop: 0,
    height: 0,
    width: '100%',
  },
  nav: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-evenly',
    flex: 1,
  },
  navItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    marginBottom: 5,
    paddingRight: 25,
    paddingLeft: 25,
  },
  navLink: {
    width: '100%',
    color: '#852D3D',
    fontSize: 22,
    fontFamily: 'acumin-pro-condensed',
    textTransform: 'uppercase',
    letterSpacing: 1.25,
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 12,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#7D777341',
    textDecoration: 'none',
  },
  dropdownItem: {
    width: '100%',
    color: '#852D3D',
    backgroundColor: 'rgba(0,0,0,0)',
    borderWidth: 0,
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 22,
    fontFamily: 'acumin-pro-condensed',
    textTransform: 'uppercase',
    letterSpacing: 1.25,
    alignItems: 'center',
    paddingLeft: 0,
    paddingBottom: 12,
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#7D777341',
    borderRadius: 0,
  },
  ucDropdown: {
    paddingRight: 25,
    paddingLeft: 25,
    width: '100%',
    marginBottom: 5,
  },
  dropdownIcon: {
    height: 15,
    width: 15,
    transform: 'none',
    transition: 'all 0.25s',
    click: {
      transform: 'rotate(180deg)',
    },
  },
  dropdownMenu: {},
  dropdownArrow: {
    width: 10,
    height: 10,
    border: 'solid #852D3D',
    borderWidth: '0 3px 3px 0',
    display: 'inline-block',
    padding: 3,
    transform: 'rotate(45deg)',
    transition: 'transform 0.15s',
    clicked: {
      transform: 'rotate(-135deg)',
    },
  },
}

export default withRouter(NavBarMobile)
