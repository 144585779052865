import React, { Component } from 'react'
import { NavLink } from './common'
import gsap, { Power2 } from 'gsap'
import { getFeed } from '../utils/ParseNavigationData'

class MarketsMobile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      linkHover: false,
      activeLink: null,
      data: [],
      allButtonHovered: false,
    }
  }

  componentDidMount() {
    this.setState({
      data: getFeed(this.props.gql.data, this.props.entry),
    })
  }

  hoverLink = (index) => {
    this.setState({
      linkHover: true,
      activeLink: index,
    })
  }

  leaveHoverLink = (index) => {
    this.setState({
      linkHover: false,
      activeLink: null,
    })
  }

  open = () => {
    const { data } = this.state

    const numItems = data[0].links.length + 1
    const height = 40 * numItems + 52

    const duration = 0.25
    const tl = gsap.timeline({
      smoothChildTiming: true,
      defaults: { duration: duration, ease: Power2.easeOut },
    })
    tl.to('#marketsContainer', { display: 'flex' }, 0)
    tl.to(
      '#marketsContainer',
      { height: height, opacity: 1, paddingTop: 25, paddingBottom: 15 },
      0,
    )
  }

  close = () => {
    const duration = 0.25
    const tl = gsap.timeline({
      smoothChildTiming: true,
      defaults: { duration: duration, ease: Power2.easeOut },
    })

    tl.to(
      '#marketsContainer',
      { opacity: 0, height: 0, paddingTop: 0, paddingBottom: 0 },
      0,
    )
    tl.to('#marketsContainer', { display: 'none' }, duration)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.display !== this.props.display) {
      if (this.props.display) {
        this.open()
      } else {
        this.close()
      }
    }
  }

  render() {
    const { activeLink, linkHover, data, allButtonHovered } = this.state
    const { hoverLink, leaveHoverLink } = this
    const { history, toggle, entry } = this.props

    const image = require('../assets/ReadMore.png')
    const hoverImage = require('../assets/ReadMore_hover_light.png')

    return (
      <div id="marketsContainer" style={styles.markets.container}>
        {data[0] &&
          data[0].links.map((m, index) => {
            return (
              <div key={index} style={styles.markets.itemWrapper}>
                <NavLink
                  onMouseEnter={() => hoverLink(index)}
                  onMouseLeave={() => leaveHoverLink(index)}
                  style={{
                    ...styles.markets.item,
                    color:
                      activeLink === index &&
                      linkHover &&
                      styles.markets.item.hover
                        ? styles.markets.item.hover.color
                        : styles.markets.item.color,
                  }}
                  item={{ url: m.url }}
                  history={history}
                  useRouter={true}>
                  {m.title}
                </NavLink>
              </div>
            )
          })}
        <div>
          <NavLink
            onMouseEnter={() => this.setState({ allButtonHovered: true })}
            onMouseLeave={() => this.setState({ allButtonHovered: false })}
            style={{
              ...styles.markets.item,
              marginTop: 12,
              fontStyle: 'italic',
              fontFamily: 'capitolina, serif',
              color: allButtonHovered ? '#EDE8E4' : '#EA6852',
            }}
            item={{ url: entry.slug }}
            history={history}
            useRouter={true}>
            {`All ${entry.title}`}
          </NavLink>
          <img
            src={allButtonHovered ? hoverImage : image}
            alt="Read More"
            style={{
              height: 13,
              width: 13,
              marginLeft: 5,
              marginBottom: 2,
            }}
          />
        </div>
      </div>
    )
  }
}

const styles = {
  markets: {
    container: {
      height: 0,
      opacity: 0,
      backgroundColor: '#562A31',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 25,
      display: 'none',
      flexDirection: 'column',
      marginLeft: -25,
      marginRight: -25,
    },
    itemWrapper: {
      paddingBottom: 10,
    },
    item: {
      padding: 0,
      margin: 0,
      display: 'inline-block',
      fontSize: 20,
      color: '#EDE8E4',
      hover: {
        color: '#E86956',
      },
    },
  },
}

export default MarketsMobile
