export const addRenderedCard = card => {
  return {
    type: 'ADD_RENDERED_CARD',
    payload: card,
  }
}

export const clearRenderedCards = () => {
  return {
    type: 'CLEAR_RENDERED_CARDS',
    payload: null,
  }
}

export const setCanLoadMore = (canLoad) => {
  return {
    type: 'SET_CAN_LOAD_MORE',
    payload: canLoad,
  }
}
