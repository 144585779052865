import React, {useEffect, useState} from 'react'
import { withSizes } from './common'
import Card from './Card'
import {useDispatch, useSelector} from 'react-redux'
import { setCanLoadMore, addRenderedCard } from "../redux/actions/feed";

const CardList  = (
  { category,
    items: _items,
    gql,
    useGql = true,
    limit,
    itemsPerPage,
    onEndLoad,
    onReachEnd,
    getStyle,
    showDate,
  }) => {
  const [items, setItems] = useState(null)

  const dispatch = useDispatch()
  const cardsRendered = useSelector(state => state.feed.cardsRendered)

  useEffect(() => {
    if(onEndLoad) {
      onEndLoad()
    }
    if(items?.length <= 0 && onReachEnd) {
      onReachEnd()
    }

    if(items?.length <= 0) {
      items.forEach(item => {
        addRenderedCard(item.slug)
      })
    }
  }, [])

  useEffect(() => {
    const itemsToFilter = useGql ? gql.items : _items
    if(itemsToFilter?.length) {
      const filteredItems = filterItems(itemsToFilter)
      setItems(filteredItems)
    }
  }, [_items, useGql, gql?.items])

  const filterItems = (argItems) => {
    let argItemsCopy = argItems
    if (category) {
      argItemsCopy = argItemsCopy.filter((i) => {
        return (i.category === category) && (!cardsRendered.includes(i.slug))
      })
    }

    if(useGql) {
      if (argItemsCopy.length <= itemsPerPage) {
        dispatch(setCanLoadMore(true))
      } else {
        dispatch(setCanLoadMore(true))
      }
    }

    if(limit) {
      argItemsCopy = argItemsCopy.slice(0, limit)
    } else if (itemsPerPage) {
      argItemsCopy = argItemsCopy.slice(0, itemsPerPage)
    }

    return argItemsCopy
  }

  return (
    <div style={getStyle(styles.container)}>
      {items?.map((item, index) => {
        return <Card data={item} index={index} total={items?.length} />
      })}
    </div>
  )
}

const styles = {
  container: {
    paddingTop: 100,
    maxWidth: 1200,
    paddingBottom: 25,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    md: {
      paddingTop: 50,
      paddingBottom: 50,
    },
  },
}

export default withSizes(CardList)
