import React, { Component } from 'react'
import { Section, Page, withSizes } from './common'
import Article from './Article'
import PathIndicator from './PathIndicator'
import Footer from './Footer'
import ContactForm from './ContactForm'
import { connect } from 'react-redux'
import DocumentMeta from 'react-document-meta'
import {
  getMeta,
  _title,
  _description,
  _image,
  _keywords,
  parseKeywords,
} from '../utils/defaultMeta'
import {
  setDarkMode,
  setGradient,
} from '../redux/actions/navBar'
import NotFound from './NotFound'
import {formatMetaTitle} from "../utils/formatMetaTitle";

class Post extends Component {
  constructor(props) {
    super(props)

    this.state = {
      header: this.getHeader(),
      sections: this.getSections(),
      notFound: false,
    }
  }

  componentDidMount() {
    const { setGradient, setDarkMode, gql } = this.props

    if(!gql.data?.entry) {
      this.setState({
        notFound: true,
      })

      return
    }

    setGradient(gql.data.entry?.heroImageTheme === 'dark')
    setDarkMode(gql.data.entry?.heroImageTheme === 'dark')
  }

  componentWillUnmount() {
    const { setGradient, setScrollTrigger } = this.props
    setGradient(false)
  }

  getHeader = () => {
    const { getStyle, gql } = this.props
    return {
      styles: getStyle(styles.header),
      slideshow: {
        slides: [
          {
            source: gql.data.entry?.heroImage[0].url,
            title: [
              {
                text: gql.data.entry?.title,
                style: {
                  fontSize: 60,
                  fontFamily: 'capitolina, serif',
                  fontWeight: 700,
                  lineHeight: 1,
                  textShadow: '2px 2px 5px rgba(0,0,0,0.75)',
                  marginBottom: 15,
                  marginLeft: 35,
                  marginRight: 35,
                  md: {
                    fontSize: 45,
                  },
                },
              },
            ],
          },
        ],
        options: {
          duration: 5000,
          transitionDuration: 500,
          infinite: true,
          arrows: false,
          indicators: false,
          onChange: (oldIndex, newIndex) => {},
        },
      },
      scrollImg: undefined,
    }
  }
  getSections = () => {
    const { data } = this.props.gql
    return data.entry && (
      <Section styles={styles.section}>
        <PathIndicator />
        <Article data={data.entry} />
      </Section>
    )
  }
  render() {
    const { sections, header, notFound } = this.state
    const { metadata } = this.props
    const { entry } = this.props.gql.data

    return notFound ? <NotFound /> : (
      <DocumentMeta
        {...getMeta(
          formatMetaTitle(entry.title || _title, metadata?.siteName, metadata?.shortSiteName, metadata?.titleDelimiter, metadata?.siteNameCharacterLimit),
          entry.seoDescription || _description,
          entry?.heroImage[0]?.url || _image,
          entry.seoKeywords.length > 0
            ? parseKeywords(entry.seoKeywords)
            : _keywords,
        )}>
        <Page
          styles={styles.page}
          header={header}
          sections={sections}
          showFooter={true}
          showContactForm={true}
          footer={<Footer />}
          contactForm={<ContactForm />}
        />
      </DocumentMeta>
    )
  }
}

const styles = {
  page: {
    container: {
      backgroundColor: 'white',
    },
  },
  section: {
    container: {
      paddingLeft: 75,
      paddingRight: 75,
      md: {
        paddingLeft: 35,
        paddingRight: 35,
      },
    },
  },
  header: {
    container: {
      height: 650,
      backgroundColor: '#021E27',
    },
    title: {
      backgroundColor: 'rgba(0,0,0,0)',
      fontSize: 65,
      display: 'flex',
      flexDirection: 'column',
    },
    scroll: {
      display: 'none',
    },
  },
  path: {
    container: {
      maxWidth: 1200,
      height: 100,
      display: 'flex',
      alignItems: 'center',
      color: '#B3ACA6',
    },
    links: {
      color: '#B3ACA6',
    },
  },
}

const mapStateToProps = (state) => {
  return {
    metadata: state.metadata
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setGradient: (hasGradient) => dispatch(setGradient(hasGradient)),
    setDarkMode: (darkMode) => dispatch(setDarkMode(darkMode)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSizes(Post))
