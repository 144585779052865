import React, { Component } from 'react'
import NavBarMobile from './NavBarMobile'
import NavBarDesktop from './NavBarDesktop'
import { gqlConnect } from 'react-connect-graphql-beta'

class NavBar extends Component {
  state = {
    navbar: NavBarDesktop,
  }

  handleResize = () => {
    const { navbar } = this.state

    if (window.innerWidth < 1160 && navbar !== NavBarMobile) {
      this.setState({
        navbar: NavBarMobile,
      })
    }
    if (window.innerWidth >= 1160 && navbar !== NavBarDesktop) {
      this.setState({
        navbar: NavBarDesktop,
      })
    }
  }

  componentDidMount() {
    const { handleResize } = this

    if (window.innerWidth < 1160) {
      this.setState({
        navbar: NavBarMobile,
      })
    }

    window.addEventListener('resize', handleResize)
  }

  render() {
    const { navbar } = this.state
    const { data } = this.props.gql

    const NavType = navbar

    return <NavType data={data} />
  }
}

export default gqlConnect('navigation')(NavBar)
