import React, {useEffect, useState} from 'react'
import DocumentMeta from "react-document-meta";
import {_description, _image, _keywords, getMeta} from "../utils/defaultMeta";
import {Page, Section, withSizes} from "./common";
import Footer from "./Footer";
import {withSplash} from "../hocs/withSplash";
import {formatMetaTitle} from "../utils/formatMetaTitle";
import {useDispatch, useSelector} from "react-redux";
import {setDarkMode} from "../redux/actions/navBar";
import {gqlConnect} from "react-connect-graphql-beta";
import * as path from "path";
import {setDocs} from "../redux/actions/documentation";

const DocumentationEntry = ({ gql, getStyle }) => {
    const [documents, setDocuments] = useState(null)
    const [product, setProduct] = useState(null)
    const [hoveredDoc, setHoveredDoc] = useState(null)

    const fixedHeaderHeight = 350

    const dispatch = useDispatch()
    const metadata = useSelector(state => state.metadata)

    const { data } = gql

    const pathnameSplit = window.location.pathname.split('/')
    const productId = pathnameSplit[pathnameSplit.length - 1]

    useEffect(() => {
        dispatch(setDarkMode(true))
    }, [])

    useEffect(() => {
        const allProducts = []
        data.forEach(document => {
            allProducts.push(...document.products)
        })
        const _product = allProducts.find(p => p.id === productId)

        const _documents = data.filter(d => !!d.products.find(p => p.id === productId))

        setDocuments(_documents)
        setProduct(_product)
    }, [data])

    const getHeader = () => {
        styles.header.container = {
            ...styles.header.container,
            height: fixedHeaderHeight,
        }
        let title = product?.partNumber
        if(product?.productName) {
            title += ` (${product.productName})`
        }
        return {
            styles: getStyle(styles.header),
            title,
            scrollImg: undefined,
        }
    }

    const onClickDoc = (doc) => {
        window.open(doc.file[0].url, '__blank')
    }

    const getSections = () => {
        return (
            <Section styles={styles.section}>
                <div style={styles.documentsContainer}>
                    {documents?.map((doc, index) => {
                        return (
                            <div style={styles.documentWrapper} onClick={() => onClickDoc(doc)} onMouseEnter={() => setHoveredDoc(index)} onMouseLeave={() => setHoveredDoc(null)}>
                                <img src={require('../assets/DocIcon.png')} style={styles.documentIcon} />
                                <div style={{...styles.documentLink, color: hoveredDoc === index ? '#E86956': '#562a31'}}>{doc.title}</div>
                            </div>
                        )
                    })}
                </div>
            </Section>
        )
    }

    const metaTitle = formatMetaTitle(product?.partNumber || 'Product Documentation', metadata?.siteName, metadata?.shortSiteName, metadata?.titleDelimiter, metadata?.siteNameCharacterLimit)


    return (
        <DocumentMeta {...getMeta(metaTitle, _description, _image, _keywords)}>
            <Page
                sections={getSections()}
                header={getHeader()}
                styles={styles.page}
                showContactForm={false}
                showFooter={true}
                footer={<Footer />}
            />
        </DocumentMeta>
    )
}

const styles = {
    page: {
        container: {
        },
    },
    section: {
        container: {
            backgroundColor: 'white',
            paddingLeft: 50,
            paddingRight: 50,
            paddingBottom: 50,
            paddingTop: 50,
        },
    },
    header: {
        container: {
            fontSize: 45,
            fontFamily: 'capitolina, serif',
            fontWeight: 700,
            textShadow: 'rgba(0, 0, 0, 0.75) 2px 2px 5px',
            lineHeight: 1,
        },
        md: {
            container: {
                fontSize: 45,
            },
        }
    },
    topWrapper: {

    },
    headerText: {

    },
    documentsContainer: {
      width: '100%',
      maxWidth: 1200,
      padding: 35,
    },
    documentWrapper: {
        width: '100%',
        backgroundColor: '#ede8e4',
        padding: 25,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        marginBottom: 15,
    },
    documentLink: {
        color: '#852D3D',
        fontSize: 32,
    },
    documentIcon: {
      width: 50,
        marginRight: 25,
    },
}

export default (gqlConnect('documentation')(withSizes(withSplash(DocumentationEntry))))
