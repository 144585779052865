import { getPostPreview } from './utils/GetPostPreview'

export default [
  {
    id: 'homepage',
    mapToProps: (res) => {
      return {
        ...res.data.entries[0],
      }
    },
    query: `query getHomePage {
      entries(section:["pages"], level:1, type:"page", pageOptions:["isHomepage"]) {
        ... on pages_page_Entry {
          id,
          slug,
          sectionId,
          typeHandle,
          title,
          pageOptions,
                                seoKeywords{
        ... on seoKeywords_keyword_BlockType{
          keywordTitle,
        },
      },
      seoDescription,
      seoImage {
        url,
        },
          slides {
            ... on slides_slide_BlockType {
              image {
                url,
                mimeType,
              }
              text,
              slideType
            }
          },
          headerActive,
                    buttons{
            ... on buttons_button_BlockType{
              buttonText
              buttonEntry{
                uri
                url
                slug
              }
              buttonUrl
            }
          }
          headerFullHeight,
          children {
            id,
            typeHandle,
          }
        }
      }
    }`,
  },
  {
    id: 'page',
    mapToProps: (res) => {
      return {
        ...res.data.entries[0],
      }
    },
    query: `query getPage($slug:[String]) {
      entries(section:["pages"], level:1, type:"page", slug:$slug) {
        ... on pages_page_Entry {
          id,
          slug,
          sectionId,
          typeHandle,
          title,
          pageOptions,
                      seoKeywords{
        ... on seoKeywords_keyword_BlockType{
          keywordTitle,
        },
      },
      seoDescription,
      seoImage {
        url,
        },
          slides {
            ... on slides_slide_BlockType {
              image {
                url,
                mimeType,
              }
              text,
              slideType
            }
          },
          headerActive,
          buttons{
            ... on buttons_button_BlockType{
              buttonText
              buttonEntry{
                uri
                url
                slug
              }
              buttonUrl
            }
          }
          headerFullHeight,
          children {
            id,
            typeHandle,
          }
        }
      }
}`,
  },
  {
    id: 'section',
    mapToProps: (res) => {
      return {
        backgroundColor: res.data.entries[0].backgroundColor[0]?.colorValue,
        children: res.data.entries[0].children,
      }
    },
    query: `query getSection($id:[QueryArgument]) {
      entries(section:["pages"], level:2, id:$id, type:"pageSection") {
        ... on pages_pageSection_Entry {
          backgroundColor {
            ... on colors_color_Entry {
              colorValue
            }
          },
          children {
            id,
            typeHandle
          }
        }
      }
    }`,
  },
  {
    id: 'blurb',
    mapToProps: (res) => {
      return {
        title: res.data.entries[0]?.heading,
        text: res.data.entries[0]?.body,
      }
    },
    query: `query getBlurb($id:[QueryArgument]) {
      entries(section:["pages"], level:3, id:$id, type:"blurb") {
        ... on pages_blurb_Entry {
          heading,
          body,
        }
      }
    }`,
  },
  {
    id: 'imageBlurb',
    mapToProps: (res) => {
      return {
        title: res.data.entries[0]?.imageBlurb[0].heading,
        text: res.data.entries[0]?.imageBlurb[0].imageBlurbText,
        backgroundImage: res.data.entries[0]?.imageBlurb[0].backgroundImage[0]?.url
      }
    },
    query: `
    query getImageBlurb($id:[QueryArgument]) {
      entries(section:["pages"], level:2, id:$id, type:"imageBlurb") {
        ... on pages_imageBlurb_Entry {
          imageBlurb{
            ... on imageBlurb_imageblurb_BlockType{
              heading
              imageBlurbText
              backgroundImage{
                url
              }
            }
          }
        }
      }
    }
    `
  },
  {
    id: 'tileGrid',
    mapToProps: (res) => {
      return {
        type: res.data.entries[0].tileType,
        numCols: res.data.entries[0].numberOfColumns,
        tiles: res.data.entries[0].tileGrid.map((tile) => {
          return {
            title: tile.tileTitle,
            text: tile.text,
            img: tile.image[0].url,
            url: tile.tileURL,
            pageEntry: tile.pageEntry.length > 0 ? tile.pageEntry[0].uri : null,
          }
        }),
      }
    },
    query: `query getTileGrid($id:[QueryArgument]) {
      entries(section:["pages"], level:3, id:$id, type:"tileGrid") {
        ... on pages_tileGrid_Entry {
          tileType,
          numberOfColumns,
          tileGrid {
            ... on tileGrid_tile_BlockType {
              tileTitle,
              text,
              image {
                url,
              },
              tileURL,
              pageEntry {
                uri,
              },
            }
          }
        }
      }
    }`,
  },
  {
    id: 'cardList',
    mapToProps: (res) => {
      return {
        items: res.data.entries[0].list.map((item) => {
          return {
            title: item.heading,
            text: item.body,
            imageUrl: item.image[0].url,
            buttonText: item.buttonText ? item.buttonText : 'Read More',
            itemUrl: item.itemURL,
            pageEntry: item.pageEntry.length > 0 ? item.pageEntry[0].uri : null,
          }
        }),
      }
    },
    query: `query getList($id:[QueryArgument]) {
      entries(section:["pages"], level:3, id:$id, type:"list") {
        ... on pages_list_Entry {
          list {
            ... on list_item_BlockType{
              itemURL,
              pageEntry{
                uri,
              },
              image {
                url,
              },
              heading,
              body,
              buttonText,
            }
          }
        }
      }
    }`,
  },
  {
    id: 'timeline',
    mapToProps: (res) => {
      return {
        data: res.data.entries.filter(
          (r) => r.__typename === 'pages_timeline_Entry',
        ),
      }
    },
    query: `query getTimeline{
  entries{
    ... on pages_timeline_Entry{
      timelineHeading,
      timelineItems{
        ... on timelineItems_item_BlockType{
          timelineItemHeading,
          imageSize,
          timelineItemImage{
            url,
          },
          timelineItemDate,
          timelineItemBody,
          timelineExternalUrl,
          timelineLinkPage{
            uri,
          },
          timelineDateFields,
        }
      }
    }
  }
}`,
  },
  {
    id: 'footer',
    mapToProps: (res) => {
      return res
    },
    query: `query getFooter{
  globalSets{
    ... on footer_GlobalSet{
      addressHeader,
      contactHeader,
      aboutHeader,
      footerAddressColumn{
        ... on footerAddressColumn_addressLine_BlockType{
          addressLine,
        }
      },
      footerContactColumn{
        ... on footerContactColumn_email_BlockType{
          emailAddress,
          emailSubject,
        },
        ... on footerContactColumn_phoneNumber_BlockType{
          phoneNumberType,
          phoneNumber,
        }
      },
      footerAboutColumn{
        ... on footerAboutColumn_pageLink_BlockType{
          page {
            id,
            title,
            slug,
            uri,
          },
          displayName,
        }
      },
      footerSocialMediaLinks{
        ... on footerSocialMediaLinks_socialLink_BlockType{
          socialName,
          socialIcon{
            url,
          },
          socialUrl,
        }
      },
      footerCopyrightNotice,
    },
  }
}`,
  },
  {
    id: 'feed',
    mapToProps: (res) => {
      return {
        itemsPerPage: res.data.entries[0].itemsPerPage,
        channelHandle: res.data.entries[0].channelHandle,
        category: res.data.entries[0].feedCategory[0]?.title,
        showDate: res.data.entries[0].showDate,
      }
    },
    query: `query getFeed($id:[QueryArgument]) {
      entries(section:["pages"], level:3, id:$id, type:"feed") {
        ... on pages_feed_Entry {
          itemsPerPage,
          showDate,
          channelHandle,
          feedCategory{
            title,
          },
        }
      }
    }`,
  },
  {
    id: 'posts',
    mapToProps: (res) => {
      return {
        items: res.data.entries.map((item) => {
          const preview = getPostPreview(item)
          return {
            title: item.title ? item.title : null,
            slug: item.slug,
            uri: item.uri,
            text: preview,
            imageUrl: item.heroImage ? item.heroImage[0]?.url : null,
            buttonText: item.buttonText ? item.buttonText : 'Read More',
            date: item.postDate ? item.postDate : null,
            category: item.channelEntryCategory
              ? item.channelEntryCategory[0]?.title
              : null,
          }
        }),
      }
    },
    query: `query getPosts($handle:String, $limit:Int, $offset:Int){
            entries(section:[$handle], level:0, limit:$limit, offset:$offset) {
                title,
                slug,
                uri,
                postDate,
    ... on services_services_Entry{
                  channelEntryCategory{
                    title,
                  },
                  title,
                  postPreview,
                  heroImage{
                    url,
                  },
                },
    ... on markets_markets_Entry{
                  title,
                  postPreview,
                  heroImage{
                    url,
                  },
                }
                ... on news_newsPosts_Entry{
                  postPreview,
                  heroImage{
                    url,
                  },
                  newsPostBody{
                    ... on newsPostBody_text_BlockType{
                      typeHandle
                      text
                    }
                    ... on newsPostBody_asset_BlockType{
                      typeHandle
                      asset{
                        url
                      }
                      caption
                      position
                    }
                    ... on newsPostBody_quote_BlockType{
                      typeHandle
                      text
                      position
                    }
                  }
                }
              }
            }`,
  },
  {
    id: 'routes',
    mapToProps: (res) => {
      return {
        slugs: res.data.entries.map((entry) => entry.slug),
      }
    },
    query: `query getRoutes{
      entries(section:["pages"], level:1, type:"page") {
        ... on pages_page_Entry {
          slug,
        }
      }
    }`,
  },
  {
    id: 'navigation',
    mapToProps: (res) => {
      return res
    },
    query: `query getNavigation{
  entries(section:["navigation"], level:1){
    ... on navigation_link_Entry{
      title,
      navLinkName,
      slug,   
      navLinkUrl,
      navLinkPage{
        title,
        slug,
      },
      children{
      ... on navigation_feed_Entry{
          title,
          navChannelHandle,
          navFeedCategory{
            slug,
            title,
          },
        },
        ... on navigation_group_Entry{
          title,
          navGroupName,
          children{
            ... on navigation_feed_Entry{
              title,
              navChannelHandle,
              navFeedCategory{
                slug,
                title,
              },
            },
          },
        },
      },
    },
  }
}`,
  },
  {
    id: 'navCategories',
    mapToProps: (res) => {
      return res
    },
    query: `query getChannelEntries($handle:[String]){
    entries(section:$handle){
    title,
    ... on services_services_Entry{
      title,
      uri,
      slug,
      channelEntryCategory{
        title,
      },
      children{
        title,
      }
    },
    ... on markets_markets_Entry{
      title,
      uri,
      slug,
    }
  }
}`,
  },
  {
    id: 'post',
    mapToProps: (res) => {
      return res
    },
    slug: true,
    query: `query getPost($section:[String], $slug:[String]){
            entry(section:$section, slug:$slug){
                title,
                id,            
                title,
                slug,
                uri,
                postDate,
                ... on news_newsPosts_Entry{
                  heroImage{
                    url,
                  },
                  heroImageTheme,
                              seoKeywords{
        ... on seoKeywords_keyword_BlockType{
          keywordTitle,
        },
      },
      seoDescription,
                  author{
                    name,
                  },
                  dropCap,
                  newsPostBody{
                    ... on newsPostBody_text_BlockType{
                      typeHandle
                      text
                    }
                    ... on newsPostBody_asset_BlockType{
                      typeHandle
                      asset{
                        url
                      }
                      caption
                      position
                    }
                    ... on newsPostBody_quote_BlockType{
                      typeHandle
                      text
                      position
                    }
                  }
                }
              }
}`,
  },
  {
    id: 'channelRoutes',
    mapToProps: (res) => {
      return res
    },
    slug: true,
    query: `query getChannelRoutes {
      globalSet(handle:"redirects"){
... on redirects_GlobalSet{
      redirects{
        ... on redirects_redirect_BlockType{
          oldUri
          newUri
        }
      }
    }
  }
      entries(section:["news", "markets", "services"], level:0) {
        sectionHandle,
        ... on news_newsPosts_Entry {
          uri,
          slug,
        },
\t\t\t\t... on markets_markets_Entry{
          uri,
          slug,
        },
    ... on services_services_Entry{
      uri,
      slug,
    }
    }
    }`,
  },
  {
    id: 'contactForm',
    mapToProps: (res) => {
      return res
    },
    query: `query getContactForm{
  globalSet{
... on contactForm_GlobalSet{
      header,
      smallFields{
        ... on smallFields_inputField_BlockType{
          inputFieldDisplayName,
        }
      },
      confirmationEmailSubject,
      confirmationEmailMessage,
      fromEmail,
      toEmail,
      largeFieldDisplayName,
      buttonDisplayName,
    }
  }
}`,
  },
  {
    id: 'imageSlider',
    mapToProps: (res) => {
      return res.data.entries[0]
    },
    query: `query getImageSlider($id:[QueryArgument]) {
  entries(id:$id){
    ... on pages_imageSlider_Entry {
      title,
      imageSliderItems{
      ... on imageSliderItems_imageSliderItem_BlockType{
          imageSliderItemUrl,
          imageSliderItemImage{
            url,
          },
          linkToPage{
            slug,
          }
        }
      },
    }
  }
}`,
  },
  {
    id: 'search',
    mapToProps: (res) => {
      return {
        data: res,
      }
    },
    query: `query _search($target:String){
  entries(section:["markets", "news", "services"], search:$target){
    ... on news_newsPosts_Entry{
      title,
      heroImage{
        url,
      },
      postDate,
      postPreview,
      newsPostBody{
        ... on newsPostBody_text_BlockType{
          text,
          typeHandle,
        }
      },
      slug,
      uri,
      searchScore,
    }
    ... on markets_markets_Entry{
      title,
      heroImage{
        url,
      },
      postPreview,
      slug,
      uri,
      searchScore,
    }
    ... on services_services_Entry{
      title,
      heroImage{
        url,
      },
      slug,
      uri,
      postPreview,
      searchScore,
    }
  }
}`,
  },
  {
    id: 'mapView',
    mapToProps: (res) => {
      return {
        data: res.data.entries,
      }
    },
    query: `query getMap($id:[QueryArgument]){
  entries(section:"pages",id:$id, type:"mapView"){
    ... on pages_mapView_Entry{
        pinLocation{
          latitude,
          longitude,
        }
    }
  }
}`,
  },
  {
    id: 'parallax',
    mapToProps: (res) => {
      return {
        image: res.data.entries[0].components[0].parallaxImage[0].url,
        showCard: res.data.entries[0].components[0].showCard,
        cardText: res.data.entries[0].components[0].cardText?.replace(
          /(<([^>]+)>)/gi,
          '',
        ),
      }
    },
    query: `query getParallax($id:[QueryArgument]){
  entries(id:$id, type:"component"){
    ... on pages_component_Entry{
      title,
    \tslug,
      uri,
  \t  components{
        ... on components_parallax_BlockType{
          parallaxImage{
            url,
          },
          showCard,
          cardText,
        },
      },
    },
  }
}`,
  },
  {
    id: 'component',
    slug: true,
    mapToProps: (res) => {
      return {
        data: res.data.entries[0],
      }
    },
    query: `query getComponent($slug:[String], $section:[String]){
      entries(slug:$slug, section:$section){
      ... on services_services_Entry{
          title,
            slug,
            uri,
            channelEntryCategory
          {
            title,
          },
          postPreview,
            seoKeywords{
          ... on seoKeywords_keyword_BlockType{
              keywordTitle,
            }

          },
          seoDescription,
            heroImage{
            url,
          },
          heroImageTheme,
            components{
              ... on components_componentText_BlockType{
                text,
                typeHandle,
              }
          ... on components_parallax_BlockType{
              id,
                typeHandle,
                parallaxImage{
                url,
              },
              showCard,
                cardText,
            },
          ... on components_blurb_BlockType{
              id,
                typeHandle,
                heading,
                body,
            },
          ... on components_listItem_BlockType{
              id,
                typeHandle,
                heading,
                body,
                buttonText,
                itemUrl,
                pageEntry{
                title,
                  slug,
                  uri,
              },
              image{
                url,
              },
              date,
            },
          ... on components_gridItem_BlockType{
              id,
                typeHandle,
                tileTitle,
                text,
                image{
                url,
              },
              tileURL,
                pageEntry{
                title,
                  slug,
                  uri,
              }
            }
          },
        },
      ... on markets_markets_Entry{
          title,
            slug,
            uri,
            postPreview,
            heroImage{
            url,
          },
          heroImageTheme,

            seoKeywords{
          ... on seoKeywords_keyword_BlockType{
              keywordTitle,
            },
          },
          seoDescription,
            components{
          ... on components_parallax_BlockType{
              id,
                typeHandle,
                parallaxImage{
                url,
              },
              showCard,
                cardText,
            },
              ... on components_componentText_BlockType{
              typeHandle,
                text,
              }
          ... on components_blurb_BlockType{
              id,
                typeHandle,
                heading,
                body,
            },
          ... on components_listItem_BlockType{
              id,
                typeHandle,
                heading,
                body,
                buttonText,
                itemUrl,
                pageEntry{
                title,
                  slug,
                  uri,
              },
              image{
                url,
              },
              date,
            },
          ... on components_gridItem_BlockType{
              id,
                typeHandle,
                tileTitle,
                text,
                image{
                url,
              },
              tileURL,
                pageEntry{
                title,
                  slug,
                  uri,
              }
            }
          },
        }
      }
    }
`,
  },
  {
    id: 'contactChannels',
    mapToProps: (res) => {
      return {
        data: res.data.entries[0],
      }
    },
    query: `query getContactChannels($id:[QueryArgument]){
      entries(id:$id, type:"contactChannels"){
        ... on pages_contactChannels_Entry{
          title1,
          title2,
          title3,
          enabled1,
          enabled2,
          enabled3,
          contactEntries{
            ... on contactEntries_phoneChannel_BlockType{
              __typename,
              phoneTitle,
              phoneNumber,
            },
          ... on contactEntries_emailChannel_BlockType{
              emailTitle,
              emailAddress,
            }
            ... on contactEntries_textChannel_BlockType{
              textTitle,
              channelText,
            }
          }
                contactEntries2{
            ... on contactEntries2_phoneChannel_BlockType{
              __typename,
              phoneTitle,
              phoneNumber,
            },
        ... on contactEntries2_emailChannel_BlockType{
              emailTitle,
              emailAddress,
            }
            ... on contactEntries2_textChannel_BlockType{
              textTitle,
              channelText,
            }
          }
          contactEntries3{
            ... on contactEntries3_phoneChannel_BlockType{
              __typename,
              phoneTitle,
              phoneNumber,
            },
          ... on contactEntries3_emailChannel_BlockType{
              emailTitle,
              emailAddress,
            }
            ... on contactEntries3_textChannel_BlockType{
              textTitle,
              channelText,
            }
          }
        }
      }
    }

`,
  },
  {
    id: 'privacy',
    mapToProps: (res) => {
      return {
        data: res.data.entry,
      }
    },
    query: `query getPrivacyPolicy{
  entry(type:"privacyPolicy"){
    ... on privacyPolicy_privacyPolicy_Entry{
      title,
      privacyDate,
      privacyText,
                                      seoKeywords{
        ... on seoKeywords_keyword_BlockType{
          keywordTitle,
        },
      },
      seoDescription,
      seoImage {
        url,
        },
    }
  }
}`,
  },
  {
    id: 'terms',
    mapToProps: (res) => {
      return {
        data: res.data.entry,
      }
    },
    query: `query getTerms{
      entry(type:"termsConditions"){
        ... on termsConditions_termsConditions_Entry{
          privacyDate,
          privacyText,
          title,
        }
      }
    }`,
  },
  {
    id: 'text',
    mapToProps: (res) => {
      return {
        data: res.data.entries[0],
      }
    },
    query: `
    query getText($id:[QueryArgument]){
      entries(section:"pages",id:$id, type:"pageTextEntry"){
        ... on pages_pageTextEntry_Entry{
          title, 
          pageTextBody,
          bodyTextAlignment,
        } 
      }
    }`,
  },
  {
    id: 'process',
    mapToProps: (res) => {
      return {
        data: res.data.entries[0],
      }
    },
    query: `
    query getProcess($id:[QueryArgument]){
  entries(section:["pages"], level:3, id:$id,type:"process"){
    ... on pages_process_Entry{
      process{
        ... on process_processItem_BlockType{
          icon{
            url
          }
          processItemTitle
          text
        }
      }
      processBackgroundColor{
        ... on colors_color_Entry{
          colorValue
        }
      }
    }
  }
}
    `
  },
  {
    id: 'metadata',
    mapToProps: (res) => {
      const metadata = res?.data?.globalSets?.find(s => s.__typename === 'metadata_GlobalSet')
      return {
        ...metadata,
      }
    },
    query: `
    query getMetadata {
      globalSets{
        ... on metadata_GlobalSet{
          siteName,
          shortSiteName,
          titleDelimiter,
          siteNameCharacterLimit,
          __typename
        }
      }
    }
    `
  },
  {
    id: 'documentation',
    mapToProps: (res) => {
      return {
        data: res.data.entries,
      }
    },
    query: `
    query getDocumentation{
     entries(section:"documentation"){
    ... on documentation_documentation_Entry{
      id
      title
      file{
        url
      }
      products{
        ... on products_products_Entry{
          id
          productName
          partNumber
          productImage{
            url
          }
        }
      }
    }
  }
}`
  }
]
