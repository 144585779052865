export const addClassToHtmlTag = (html, element, _class) => {
  let target, targetRegex

  if (Array.isArray(element)) {
    element.forEach((e) => {
      target = `<${e}`
      targetRegex = new RegExp(target, 'g')

      html = html.replace(targetRegex, `<${e} class="${_class}"`)
    })
  } else {
    target = `<${element}`
    targetRegex = new RegExp(target, 'g')
    html = html.replace(targetRegex, `<${element} class="${_class}"`)
  }

  return html
}
