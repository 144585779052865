import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import 'bootstrap/dist/css/bootstrap.css'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import GQLProvider from 'react-connect-graphql-beta'
import gql from './gql.js'
import introspectionQueryResultData from './fragmentTypes.json'
import store from './redux/store'
import rootReducer from './redux/reducers'
import thunkMiddleware from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import { ParallaxProvider } from 'react-scroll-parallax'
import SplashScreen from './components/SplashScreen'
import request from 'request'
import config from './config'

request(`${window.location.origin}/config.json`, { json: true }, (err, res) => {
  config.craftApi = res.body.craftApi
  config.atcompApi = res.body.atcompApi

  const reduxStore = createStore(rootReducer, applyMiddleware(thunkMiddleware))

  const httpLink = createHttpLink({ uri: config.craftApi.url })

  const authLink = setContext((_, { headers }) => {
    let m = document.location.href.match(/\btoken=([^&]+)/);
    let previewToken = m ? `?token=${m[1]}` : '';

    let apiUrlWithToken = `${config.craftApi.url}${previewToken}`;
    return {
      headers: {
        ...headers,
        authorization: config.craftApi.token,
      },
      uri: apiUrlWithToken,
    }
  })

  const link = authLink.concat(httpLink)

  const gqlStore = GQLProvider.createStore({
    link,
    gql,
    introspectionFragmentMatcherOptions: {
      introspectionQueryResultData,
    },
  })

  setTimeout(() => {
    ReactDOM.render(
      <GQLProvider
        fallback={SplashScreen}
        store={gqlStore}
        fallbackOnce={true}
        fadeInOnce={true}>
        <Provider store={reduxStore}>
          <ParallaxProvider>
            <App />
          </ParallaxProvider>
        </Provider>
      </GQLProvider>,
      document.getElementById('root'),
    )
  }, 50)

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister()

})
