import React, { Component } from 'react'
import './App.css'
import { Routes, NavBar, ChannelRoutes, ShareButton } from './components'
import { BrowserRouter, Switch } from 'react-router-dom'
import MetadataHandler from './components/MetadataHandler'
class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <NavBar />
        <MetadataHandler />
        <ShareButton />
        <Switch>
          <Routes />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default App
