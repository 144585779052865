import React, {Component} from 'react'
import { Fade, Slide, Zoom } from 'react-slideshow-image'
import mergeStyles from './utils/StyleMerge'
import withSizes from './utils/Sizes'
import deepmerge from 'deepmerge'
import SplitWrappedLines from './SplitWrappedLines'
import Radium from 'radium'
import Reveal from 'react-reveal'
import './animations.css'
import {Button} from "./index";
import { withRouter } from 'react-router-dom'

class Slideshow extends Component {
  onClickButton = (button) => {
    const { history } = this.props

    if(button?.buttonUrl) {
      window.open(button?.buttonUrl, '_blank')
    } else if(button?.buttonEntry) {
      history.push(button.buttonEntry[0]?.uri)
    }
  }

  renderSlide = () => {
    const { slides, options, styles, getStyle } = this.props
    const _styles = deepmerge(styles, slideStyles)

    return (
      <Slide {...options} className="slideshowWrapper">
        {slides.map((slide, index) => {
          return(
            <div key={index} style={_styles.slide}>
              <div style={_styles.imageWrapper}>
                {slide.source && <img src={slide.source} style={_styles.image} alt={`img${index}`}/>}
              </div>
              <div style={_styles.titleWrapper}>
                {slide.title.map((title, subIndex) => {
                  return ( index === 0 ? (
                    <Reveal effect="fadeInUp-Header" duration={1000}>
                      <div key={subIndex} style={getStyle(title.style)}>{title.text}</div>
                    </Reveal>
                    ) :
                      <div key={subIndex} style={getStyle(title.style)}>{title.text}</div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </Slide>
    )
  }
  renderFade = () => {
    const { slides, options, styles, getStyle, buttons } = this.props
    const { onClickButton } = this

    const _styles = deepmerge(styles, fadeStyles)

    return (
      <Fade autoplay={slides?.length > 1} canSwipe={false} {...options} className="slideshowWrapper">
        {slides.map((slide, index) => {
          return(
          <div key={index} style={getStyle(_styles.slide)}>
            <div style={getStyle(_styles.imageWrapper)}>
              {slide.source && <img src={slide.source} style={getStyle(_styles.image)} alt={`img${index}`}/>}
            </div>
            <div style={getStyle(_styles.titleWrapper)}>
              <div />
              <div style={getStyle(styles.textWrapper)}>
              {options.splitWrappedLines ?
                <SplitWrappedLines text={slide.title} styles={{ line: styles.titleLine }} />
                : slide.title.map((title, subIndex) => {
                  return (( index === 0 ? (
                        <Reveal effect="fadeInUp-Header" duration={1000}>
                          <span key={subIndex} style={getStyle(title.style)}>{title.text}</span>
                        </Reveal>
                      ) :
                      <span key={subIndex} style={getStyle(title.style)}>{title.text}</span>
                  ))
              })}
                </div>
              <div style={getStyle(styles.buttonWrapper)}>
                {buttons?.map(b => {
                  return (
                    <Button styles={getStyle(styles.button)} onClick={() => onClickButton(b)}>{b.buttonText}</Button>
                  )
                })}
              </div>
            </div>
          </div>
          )
        })}
      </Fade>
    )
  }
  renderZoom = () => {

  }
  render() {
    const { styles, getStyle, type } = this.props
    const { renderSlide, renderFade, renderZoom } = this
    let slideshow = null

    switch(type) {
      case 'slide':
        slideshow = renderSlide()
        break
      case 'zoom':
        slideshow = renderZoom()
        break
      default:
        slideshow = renderFade()
        break
    }

    return (
      <div style={getStyle(styles.container)}>
        {slideshow}
      </div>
    )
  }
}

const fadeStyles = {

}

const slideStyles = {

}

const defaultStyles = {
  buttonWrapper: {
    display: 'flex',
    // padding: 25,
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    xs: {
      bottom: 130,
    },
  },
  button: {
    height: 60,
    backgroundColor: '#862C3C',
    borderWidth: 0,
    color: '#FFFFFF',
    width: 300,
    borderRadius: 0,
    fontFamily: 'acumin-pro-condensed, sans-serif',
    letterSpacing: 1.25,
    margin: 15,
    fontSize: 20,
    md: {
      fontSize: 18,
      height: 50,
      width: 250,
    },
    sm: {
      margin: 5,
    },
    hovered: {
      backgroundColor: '#E86956',
      borderWidth: 0,
      color: '#FFFFFF',
    },
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    marginTop: 110,
    marginBottom: 50,
    sm: {
      marginTop: 25,
      marginBottom: 25,
    },
  },
  container: {
    width: '100%',
    height: '100%',
  },
  slide: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  imageWrapper: {
    width: '100%',
    height: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  titleWrapper: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
}

export default mergeStyles(defaultStyles)(withRouter(withSizes(Radium(Slideshow))))
