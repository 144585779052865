import React, { Component } from 'react'
import {
  Button,
  Footer as FooterComponent,
  withSizes,
} from './common'
import { Media } from 'reactstrap'
import { withRouter } from 'react-router-dom'

const styles = {
  container: {
    backgroundColor: '#562A31',
    //height: 900,
    /*md: {
      height: 450,
    },
    sm: {
      height: 450,
    },
    xs: {
      height: 800,
    }*/
  },
  widthRestrict: {
    minWidth: 275,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 50,
    paddingBottom: 50,
    paddingLeft: 35,
    paddingRight: 35,
    md: {
      paddingTop: 25,
    },
  },
  footerComp: {
    container: {
      display: 'flex',
      backgroundColor: '#562A31',
    },
    content: {
      flex: 1,
      display: 'flex',
      width: 'auto',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
    },
    defaultColumn: {
      whiteSpace: 'nowrap',
      margin: 10,
      marginBottom: 30,
      marginLeft: 0,
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    defaultHeader: {
      color: '#E86956',
      fontSize: 24,
      paddingBottom: 10,
      fontFamily: 'capitolina, serif',
      fontStyle: 'italic',
    },
    defaultText: {
      fontSize: 16,
      color: '#EDE8E4',
      marginBottom: 10,
    },
    defaultLink: {
      fontSize: 16,
      color: '#EDE8E4',
      marginBottom: 10,
      display: 'block',
    },
    columns: [{}, {}, {}, {}],
    xs: {
      defaultColumn: {
        marginBottom: 10,
      },
    },
  },
  iconWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    xs: {
      paddingTop: 20,
      paddingBottom: 35,
    },
    md: {
      paddingTop: 30,
      paddingBottom: 55,
    },
  },
  divider: {
    height: 1,
    backgroundColor: '#EDE8E4',
    width: '100%',
    xs: {
      marginBottom: 35,
    },
    md: {
      marginBottom: 55,
    },
  },
  socials: {
    width: 32,
    height: 32,
  },
  button: {
    height: 35,
    width: 35,
    position: 'relative',
    borderWidth: 2,
    borderColor: '#EDE8E4',
    borderStyle: 'solid',
    borderRadius: '50%',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    filter: 'none',
    alignItems: 'center',
    hovered: {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#EDE8E4',
      filter:
        'invert(57%) sepia(14%) saturate(4670%) hue-rotate(325deg) brightness(95%) contrast(91%)',
    },
  },
  buttonImg: {
    height: 15,
    width: 15,
  },
  copyright: {
    textAlign: 'left',
    alignSelf: 'flex-start',
    color: '#EDE8E4',
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'acumin-pro-condensed, sans-serif',
    letterSpacing: 1.25,
  },
  upArrow: {
    borderStyle: 'solid',
    borderColor: '#EDE8E4',
    borderWidth: '0 2px 2px 0',
    display: 'inline-block',
    padding: 5,
    marginTop: 4,
    transform: 'rotate(-135deg)',
  },
}

class FooterMobile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      intervalId: 0,
      hovered: false,
      activeIndex: null,
    }

    this.handleScroll = this.handleScroll.bind(this)
    this.scrollStep = this.scrollStep.bind(this)
  }

  scrollStep() {
    const { intervalId } = this.state
    if (window.pageYOffset === 0) {
      clearInterval(intervalId)
    }
    window.scroll(0, window.pageYOffset - 100)
  }

  handleScroll() {
    const { scrollStep } = this
    let intervalId = setInterval(scrollStep, 0)
    this.setState({
      intervalId: intervalId,
    })
  }
  hoverImg = (index = null) => {
    this.setState({
      hovered: true,
      activeIndex: index,
    })
  }
  leaveHoverImg = () => {
    this.setState({
      hovered: false,
      activeIndex: null,
    })
  }
  render() {
    const { handleScroll, hoverImg, leaveHoverImg } = this
    const { hovered, activeIndex } = this.state
    const { columns, copyright, socials, getStyle, history } = this.props

    return (
      <div style={getStyle(styles.container)}>
        <div style={getStyle(styles.widthRestrict)}>
          <FooterComponent
            columns={columns}
            styles={getStyle(styles.footerComp)}
            useRouter={true}
            history={history}
          />
          <div style={getStyle(styles.iconWrapper)}>
            {socials.map((s, index) => {
              return (
                <React.Fragment key={index}>
                  <a href={s.url}>
                    <Media
                      onMouseEnter={() => hoverImg(index)}
                      onMouseLeave={() => leaveHoverImg()}
                      object
                      src={s.icon}
                      alt={s.name}
                      style={{
                        ...getStyle(styles.socials),
                        filter:
                          hovered && activeIndex === index
                            ? 'invert(57%) sepia(14%) saturate(4670%) hue-rotate(325deg) brightness(95%) contrast(91%)'
                            : 'none',
                      }}
                    />
                  </a>
                </React.Fragment>
              )
            })}
            <Button onClick={handleScroll} styles={getStyle(styles.button)}>
              <i style={styles.upArrow} />
            </Button>
          </div>
          <div style={getStyle(styles.divider)}></div>
          <div style={getStyle(styles.copyright)}>{copyright}</div>
        </div>
      </div>
    )
  }
}

export default withSizes(withRouter(FooterMobile))
