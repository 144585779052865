import {useDispatch} from 'react-redux'
import { gqlConnect } from 'react-connect-graphql-beta'
import {setMetadata} from "../redux/actions/metadata";

const MetadataHandler = ({ gql }) => {
  const siteName = gql?.siteName
  const shortSiteName = gql?.shortSiteName
  const titleDelimiter = gql?.titleDelimiter
  const siteNameCharacterLimit = gql?.siteNameCharacterLimit

  const dispatch = useDispatch()

  dispatch(setMetadata({ siteName, shortSiteName, titleDelimiter, siteNameCharacterLimit }))

  return null
}

export default gqlConnect('metadata')(MetadataHandler)
