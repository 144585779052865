import React, {useEffect, useState} from 'react'
import { gqlConnect } from 'react-connect-graphql-beta'
import { Section, Page, withSizes } from './common'
import {withSplash} from "../hocs/withSplash";
import DocumentMeta from "react-document-meta";
import Footer from './Footer'
import { useDispatch, useSelector } from 'react-redux'
import {setDarkMode} from "../redux/actions/navBar";
import {
    getMeta,
    _description,
    _image,
    _keywords,
} from '../utils/defaultMeta'
import {formatMetaTitle} from "../utils/formatMetaTitle";
import {useHistory} from "react-router-dom";
import {Input} from "reactstrap";
const Documentation = ({ gql, getStyle }) => {
    const [hoveredProduct, setHoveredProduct] = useState(null)
    const [documents, setDocuments] = useState(null)
    const [products, setProducts] = useState(null)
    const [filteredProducts, setFilteredProducts] = useState(null)
    const [filter, setFilter] = useState(null)

    const history = useHistory()

    const dispatch = useDispatch()
    const metadata = useSelector(state => state.metadata)

    const fixedHeaderHeight = window.innerWidth <= 767 ? 350 : 600

    const { data } = gql

    useEffect(() => {
        dispatch(setDarkMode(true))
    }, [])

    useEffect(() => {
        const allProducts = []
        const _documents = []
        data.forEach(doc => {
            _documents.push(doc)
            allProducts.push(...doc.products)
        })

        let _products = []

        allProducts.forEach(p => {
            if (!_products.find(_p => _p.id === p.id)) {
                _products.push(p)
            }
        })

        _products = _products.sort((a,b) => {
            return a.partNumber < b.partNumber ? -1 : 1
        })

        setProducts(_products)
        setFilteredProducts(_products)
        setDocuments(_documents)
    }, [data])

    useEffect(() => {
        if(filter?.length > 0) {
            setFilteredProducts(products.filter(p => p.partNumber.toLowerCase().includes(filter.toLowerCase()) || p.productName.toLowerCase().includes(filter.toLowerCase())))
        } else {
            setFilteredProducts(products)
        }
    }, [filter, products])

    const getHeader = () => {
        styles.header.container = {
            ...styles.header.container,
            height: fixedHeaderHeight,
        }
        return {
            styles: getStyle(styles.header),
            title: 'Documentation',
            scrollImg: undefined,
        }
    }

    const onClickProduct = (product) => {
        history.push('documentation/' + product.id)
    }

    const handleKeyPress = (e) => {
        setFilter(e.target.value)
    }

    const getSections = () => {
        return (
            <Section styles={styles.section}>
                <div style={styles.searchWrapper}>
                    <Input
                        onChange={(e) => handleKeyPress(e)}
                        type="text"
                        name="search"
                        id="ddSearch"
                        placeholder="Search by part # or product name..."
                        style={styles.input}
                    />
                </div>
                <div style={styles.productsContainer}>
                {filteredProducts?.map((p, index) => {
                    return (
                        <div onMouseEnter={() => setHoveredProduct(index)} onMouseLeave={() => setHoveredProduct(null)} onClick={() => onClickProduct(p)} style={styles.productWrapper} key={index}>
                            <img src={p.productImage[0]?.url} style={styles.productImage} />
                            <div style={{...styles.productPartNumber, color: hoveredProduct === index ? '#E86956': '#562a31'}}>{p.partNumber}</div>
                            <div style={{...styles.productName, color: hoveredProduct === index ? '#E86956': '#562a31'}}>{p.productName}</div>
                        </div>
                    )
                })}
                </div>
            </Section>
        )
    }

    const metaTitle = formatMetaTitle('Documentation', metadata?.siteName, metadata?.shortSiteName, metadata?.titleDelimiter, metadata?.siteNameCharacterLimit)

    return (
        <DocumentMeta {...getMeta(metaTitle, _description, _image, _keywords)}>
            <Page
                sections={getSections()}
                header={getHeader()}
                styles={styles.page}
                showContactForm={false}
                showFooter={true}
                footer={<Footer />}
            />
        </DocumentMeta>
    )
}

const styles = {
    page: {
        container: {
        },
    },
    section: {
        container: {
            backgroundColor: 'white',
            paddingLeft: 50,
            paddingRight: 50,
            paddingBottom: 50,
            paddingTop: 50,
        },
    },
    productsContainer: {
        width: '100%',
        maxWidth: 1460,
        display: 'flex',
        flexWrap: 'wrap',
    },
    productWrapper: {
        width: 350,
        height: 350,
        backgroundColor: '#ede8e4',
        padding: 25,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        marginLeft: 15,
        marginBottom: 15,
    },
    productImage: {
      width: 125,
    },
    productPartNumber: {
        marginTop: 10,
        marginBottom: 10,
        color: '#852D3D',
        fontSize: 32,
        fontWeight: 'bold',
    },
    productName: {
        color: '#852D3D',
        fontSize: 25,
        fontStyle: 'italic',
    },
    searchWrapper: {
        width: '100%',
        maxWidth: 1445,
        backgroundColor: '#ede8e4',
        borderColor: '#E86956',
        borderWidth: 2,
        borderStyle: 'solid',
        height: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 10,
        marginLeft: 15,
        marginBottom: 25,
        paddingTop: 0,
    },
    header: {
        container: {
            fontSize: 60,
            fontFamily: 'capitolina, serif',
            fontWeight: 700,
            textShadow: 'rgba(0, 0, 0, 0.75) 2px 2px 5px',
            lineHeight: 1,
        },
        md: {
            container: {
              fontSize: 45,
            },
        }
    },
    topWrapper: {

    },
    headerText: {

    },
    input: {
        backgroundColor: 'rgba(0,0,0,0)',
        borderWidth: 0,
        width: '100%',
    },
}

export default (gqlConnect('documentation')(withSizes(withSplash(Documentation))))
