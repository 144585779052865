import React, { Component } from 'react'
import { Section, Page, withSizes } from './common'
import Blurb from './Blurb'
import FloatingParallax from './FloatingParallax'
import TileGrid from './TileGrid'
import CardList from './CardList'
import Footer from './Footer'
import ContactForm from './ContactForm'
import { withRouter } from 'react-router-dom'
import DocumentMeta from 'react-document-meta'
import {
  getMeta,
  _title,
  _description,
  _image,
  _keywords,
  parseKeywords,
} from '../utils/defaultMeta'
import { connect } from 'react-redux'
import { setDarkMode } from '../redux/actions/navBar'
import NotFound from './NotFound'
import Article from './Article'
import {formatMetaTitle} from "../utils/formatMetaTitle";

class ChannelEntry extends Component {
  constructor(props) {
    super(props)

    this.state = {
      header: this.getHeader(),
      sections: this.getSections(),
      notFound: false,
    }
  }

  componentDidMount() {
    const { data } = this.props.gql
    const { setDarkMode } = this.props

    if(!data) {
      this.setState({
        notFound: true,
      })

      return
    }

    setDarkMode(data?.heroImageTheme === 'dark')
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { history } = this.props
    if (prevProps.history.location.pathname !== history.location.pathname) {
    }
  }

  getHeader = () => {
    const { getStyle } = this.props
    const { data } = this.props.gql

    return {
      styles: getStyle(styles.header),
      slideshow: {
        slides: [
          {
            source: data?.heroImage[0].url,
            title: [
              {
                text: data?.title,
                style: {
                  fontSize: 60,
                  fontFamily: 'capitolina, serif',
                  fontWeight: 700,
                  lineHeight: 1,
                  marginBottom: 15,
                  marginLeft: 35,
                  marginRight: 35,
                  textShadow: '2px 2px 5px rgba(0,0,0,0.75)',
                  md: {
                    fontSize: 45,
                  },
                },
              },
            ],
          },
        ],
        options: {
          duration: 5000,
          transitionDuration: 500,
          infinite: true,
          arrows: false,
          indicators: false,
          onChange: (oldIndex, newIndex) => {},
        },
      },
      scrollImg: undefined,
    }
  }
  getSections = () => {
    const { data } = this.props.gql

    return (
      <Section styles={styles.section}>
        {data?.components.map((component, index) => {
          let card, item
          switch (component.typeHandle) {
            case 'blurb':
              return (
                <Blurb
                  key={index}
                  useGql={false}
                  content={{ title: component.heading, text: component.body }}
                />
              )
            case 'parallax':
              return (
                <FloatingParallax
                  useGql={false}
                  key={index}
                  marginBottom={50}
                  image={component.parallaxImage[0]?.url}
                  showCard={component.showCard}
                  cardText={component.cardText.replace(/(<([^>]+)>)/gi, '')}
                />
              )
            case 'listItem':
              card = {
                title: component.heading,
                text: component.body,
                buttonText: component.buttonText,
                itemUrl: component.itemUrl,
                pageEntry: component.pageEntry[0]?.uri,
                imageUrl: component.image[0].url,
                //date: component.date,
              }
              return <CardList items={[card]} useGql={false} />
            case 'gridItem':
              item = {
                img: component.image[0].url,
                text: component.text,
                title: component.tileTitle,
                url: component.tileUrl,
                pageEntry: component.pageEntry[0]?.uri,
              }
              return <TileGrid useGql={false} tiles={[item]} />
            case 'componentText':
              return <Article isNewsPost={false} data={{newsPostBody: [{...component, typeHandle: 'text'}]}} />
            default:
              return null
          }
        })}
      </Section>
    )
  }
  render() {
    const { notFound, sections, header } = this.state
    const { history, metadata } = this.props
    const { data } = this.props.gql

    return notFound ? <NotFound /> : data ? (
      <div key={history.location.pathname}>
        <DocumentMeta
          {...getMeta(
            formatMetaTitle(data.title || _title, metadata?.siteName, metadata?.shortSiteName, metadata?.titleDelimiter, metadata?.siteNameCharacterLimit),
            data.seoDescription || _description,
            data?.heroImage[0]?.url || _image,
            data.seoKeywords.length > 0
              ? parseKeywords(data.seoKeywords)
              : _keywords,
          )}>
          <Page
            styles={styles.page}
            header={header}
            sections={sections}
            showFooter={true}
            showContactForm={true}
            footer={<Footer />}
            contactForm={<ContactForm />}
          />
        </DocumentMeta>
      </div>
    ) : null
  }
}

const styles = {
  page: {
    container: {
      backgroundColor: 'white',
    },
  },
  section: {
    container: {
      paddingLeft: 50,
      paddingRight: 50,
      paddingBottom: 50,
    },
  },
  header: {
    container: {
      height: 650,
      backgroundColor: '#021E27',
    },
    title: {
      backgroundColor: 'rgba(0,0,0,0)',
      fontSize: 65,
      display: 'flex',
      flexDirection: 'column',
    },
    scroll: {
      display: 'none',
    },
  },
}

const mapStateToProps = (state) => {
  return {
    metadata: state.metadata
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDarkMode: (darkMode) => dispatch(setDarkMode(darkMode)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withSizes(ChannelEntry)))
