import React, { Component } from 'react'
import { Button, withSizes } from './common'
import { Form, FormGroup, Input, FormText } from 'reactstrap'
import backgroundImg from '../assets/ContactForm.png'
import '../index.css'
import { gqlConnect } from 'react-connect-graphql-beta'
import { connect } from 'react-redux'
import {
  sendEmail,
  resetEmailData,
  setEmailAddress,
  setEmailMessage,
  setPhoneNumber,
  setEmailName,
} from '../redux/actions/contactForm'
import deepmerge from 'deepmerge'

class ContactForm extends Component {
  state = {
    name: '',
    email: '',
    message: '',
  }
  onChangeName = (val) => {
    const { setEmailName } = this.props
    setEmailName(val)
  }

  onChangeEmail = (val) => {
    const { setEmailAddress } = this.props
    setEmailAddress(val)
  }

  onChangeMessage = (val) => {
    const { setEmailMessage } = this.props
    setEmailMessage(val)
  }

  onChangePhone = (val) => {
    const { setPhoneNumber } = this.props
    setPhoneNumber(val)
  }

  onSubmit = (e) => {
    const {
      sendEmail,
      isSendingEmail,
      emailMessage,
      emailName,
      emailAddress,
      phoneNumber,
    } = this.props
    const { data } = this.props.gql

    e.preventDefault()

    if (isSendingEmail) {
      return
    }

    const email = {
      from: data.globalSet.fromEmail,
      to: data.globalSet.toEmail,
      confirmationEmail: data.globalSet.confirmationEmailMessage,
      confirmationEmailSubject: data.globalSet.confirmationEmailSubject,
      name: emailName,
      message: emailMessage,
      email: emailAddress,
      phone: phoneNumber,
    }

    sendEmail(email)
  }

  render() {
    const { onSubmit, onChangeEmail, onChangeMessage, onChangeName, onChangePhone } = this
    const { getStyle, emailStatusMessage, isSendingEmail  } = this.props
    const { data } = this.props.gql

    return (
      <div style={getStyle(styles.container)}>
        <div style={getStyle(styles.header)}>{data.globalSet.header}</div>
        <Form style={getStyle(styles.formWrapper)}>
          <FormGroup style={getStyle(styles.formGroup)}>
            <Input
              onChange={(e) => onChangeName(e.target.value)}
              type="text"
              name="name"
              id="name"
              placeholder={data.globalSet.smallFields[0].inputFieldDisplayName}
              style={getStyle(styles.formInput)}
            />
          </FormGroup>
          <FormGroup style={getStyle(styles.formGroup)}>
            <Input
              onChange={(e) => onChangeEmail(e.target.value)}
              type="email"
              name="email"
              id="email"
              placeholder={data.globalSet.smallFields[1].inputFieldDisplayName}
              style={getStyle(styles.formInput)}
            />
          </FormGroup>
          <FormGroup style={getStyle(styles.formGroup)}>
            <Input
              onChange={(e) => onChangePhone(e.target.value)}
              type="text"
              name="phone"
              id="phone"
              placeholder={data.globalSet.smallFields[2].inputFieldDisplayName}
              style={getStyle(styles.formInput)}
            />
          </FormGroup>
          <FormGroup style={getStyle(styles.formGroup)}>
            <Input
              onChange={(e) => onChangeMessage(e.target.value)}
              type="textarea"
              name="message"
              id="message"
              placeholder={data.globalSet.largeFieldDisplayName}
              style={getStyle(styles.formMessage)}
            />
          </FormGroup>
          <Button styles={isSendingEmail ? deepmerge(getStyle(styles.submit), getStyle(styles.submit.sending)) : getStyle(styles.submit)} onClick={onSubmit}>
            {isSendingEmail ? 'SENDING...' :  data.globalSet.buttonDisplayName}
          </Button>
          <div style={getStyle(styles.statusMessage)}>
            {emailStatusMessage}
          </div>
        </Form>
      </div>
    )
  }
}

const styles = {
  container: {
    height: 600,
    width: '100%',
    backgroundImage: `url(${backgroundImg})`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 50,
    sm: {
      padding: 35,
      height: 'auto',
    },
  },
  header: {
    fontSize: 60,
    color: '#FFFFFF',
    marginBottom: 25,
    fontFamily: 'capitolina, serif',
    fontWeight: 700,
    sm: {
      fontSize: 35,
    },
  },
  formWrapper: {
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
    sm: {
      width: '100%',
    },
  },
  formGroup: {
    sm: {
      width: '100%',
    },
  },
  formInput: {
    marginBottom: 38,
    height: 60,
    width: 381,
    backgroundColor: 'rgba(255,255,255,0.1)',
    backdropFilter: 'blur(8px)',
    WebkitBackdropFilter: 'blur(8px)',
    color: '#FFFFFF',
    borderColor: '#EDE8E4',
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: 0,
    sm: {
      marginBottom: 15,
      height: 53,
      width: '100%',
    },
    xs: {},
  },
  formMessage: {
    height: 175,
    width: 381,
    marginBottom: 38,
    marginLeft: 38,
    paddingTop: 15,
    backgroundColor: 'rgba(255,255,255,0.1)',
    backdropFilter: 'blur(8px)',
    WebkitBackdropFilter: 'blur(8px)',
    color: '#FFFFFF',
    borderColor: '#EDE8E4',
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: 0,
    sm: {
      marginBottom: 15,
      marginLeft: 0,
      height: 100,
      width: '100%',
    },
    xs: {},
  },
  submit: {
    marginLeft: 38,
    height: 60,
    backgroundColor: '#862C3C',
    borderWidth: 0,
    color: '#FFFFFF',
    width: 381,
    borderRadius: 0,
    fontFamily: 'acumin-pro-condensed, sans-serif',
    letterSpacing: 1.25,
    hovered: {
      backgroundColor: '#E86956',
      borderWidth: 0,
      color: '#FFFFFF',
    },
    sm: {
      height: 53,
      marginLeft: 0,
      width: '100%',
    },
    xs: {},
    sending: {
      backgroundColor: '#E86956',
    },
  },
  statusMessage: {
    color: '#FFFFFF',
    width: 381,
    fontSize: 14,
    marginLeft: 38,
    marginTop: 20,
    sm: {
      marginLeft: 0,
      width: '100%',
    },
  },
}


const mapStateToProps = (state) => {
  return {
    isEmailSuccessful: state.contactForm.isEmailSuccessful,
    isSendingEmail: state.contactForm.isSendingEmail,
    emailStatusMessage: state.contactForm.emailStatusMessage,
    emailMessage: state.contactForm.emailMessage,
    emailName: state.contactForm.emailName,
    emailAddress: state.contactForm.emailAddress,
    phoneNumber: state.contactForm.phoneNumber,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendEmail: (email) => dispatch(sendEmail(email)),
    setEmailMessage: (message) => dispatch(setEmailMessage(message)),
    setEmailName: (name) => dispatch(setEmailName(name)),
    setEmailAddress: (address) => dispatch(setEmailAddress(address)),
    setPhoneNumber: (phone) => dispatch(setPhoneNumber(phone)),
    resetEmailData: () => dispatch(resetEmailData()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(gqlConnect('contactForm')(withSizes(ContactForm)))
