const sortEntries = (entries, targets) => {
  let categories = []
  targets.forEach((t) => {
    categories.push(t.title)
  })

  let filtered = []
  if (targets.length <= 0) {
    filtered = entries
  } else {
    entries.forEach((e) => {
      e.channelEntryCategory.forEach((c) => {
        if (categories.includes(c.title)) {
          filtered.push(e)
        }
      })
    })
  }

  return filtered.map((f) => {
    return {
      title: f.title,
      url: `/${f.uri}`,
    }
  })
}

export const getFeed = (data, entry) => {
  return entry.children.map((c) => {
    const heading = c.children ? c.title : ''
    const feedTargets = c.children
      ? c.children[0].navFeedCategory
      : c.navFeedCategory
    return {
      heading: heading,
      type: 'links',
      links: sortEntries(data.entries, feedTargets),
    }
  })
}
