const initialState = {
  darkMode: false,
  hasGradient: false,
  scrollTrigger: 700,
}

const navBar = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DARK_MODE':
      return Object.assign({}, state, { darkMode: action.payload })
    case 'SET_GRADIENT':
      return Object.assign({}, state, { hasGradient: action.payload })
    case 'SET_SCROLL_TRIGGER':
      return Object.assign({}, state, { scrollTrigger: action.payload })
    default:
      return state
  }
}

export default navBar
