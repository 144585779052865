import React, { Component } from 'react'
import { Section as SectionComp } from './common'
import { getComponent } from '../utils/ComponentFactory'
import { withRouter } from 'react-router-dom'

class Section extends Component {
  constructor(props) {
    super(props)

    this.state = {
      children: this.getChildren(),
      backgroundColor: props.gql.backgroundColor,
    }
  }

  getChildren = () => {
    const { history, gql } = this.props
    const { children } = gql
    const pathname = history.location.pathname
    return children.map((child, index) => {
      const Component = getComponent(child.id, child.typeHandle, pathname)
      if (Component) {
        return <Component key={index} />
      } else {
        return null
      }
    })
  }

  render() {
    const { backgroundColor, children } = this.state
    const { history } = this.props

    const isContact = history.location.pathname === '/contact'

    return (
      <SectionComp
        styles={{
          ...styles,
          container: {
            ...styles.container,
            backgroundColor,
            alignItems: isContact ? 'unset' : 'center',
          },
        }}>
        {children}
      </SectionComp>
    )
  }
}

const styles = {
  container: {
    paddingLeft: 75,
    paddingRight: 75,
    paddingBottom: 50,
    md: {
      paddingLeft: 35,
      paddingRight: 35,
    },
  },
}

export default withRouter(Section)
