import React from 'react'
import createReactClass from 'create-react-class'
import {
  Section,
  Blurb,
  TileGrid,
  CardList,
  Feed,
  Page,
  Post,
  Timeline,
  ImageSlider,
  SearchPage,
  ChannelEntry,
  ContactChannels,
  GoogleMapView,
  FloatingParallax,
  Text,
} from '../components'
import { gqlConnect } from 'react-connect-graphql-beta'
import { withSplash } from '../hocs/withSplash'
import CardListNew from "../components/CardListNew";
import Process from "../components/Process";
import ImageBlurb from "../components/ImageBlurb";

const components = {
  pageSection: (id) => gqlConnect('section', { id })(Section),
  blurb: (id) => gqlConnect('blurb', { id })(Blurb),
  tileGrid: (id) => gqlConnect('tileGrid', { id })(TileGrid),
  newList: (id) => gqlConnect('cardList', { id })(CardListNew),
  list: (id) => gqlConnect('cardList', { id })(CardList),
  feed: (id) => gqlConnect('feed', { id })(Feed),
  timeline: (id) => gqlConnect('timeline', { id })(Timeline),
  imageSlider: (id) => gqlConnect('imageSlider', { id })(ImageSlider),
  mapView: (id) => gqlConnect('mapView', { id })(GoogleMapView),
  component: (id) => gqlConnect('parallax', { id })(FloatingParallax),
  contactChannels: (id) =>
    gqlConnect('contactChannels', { id })(ContactChannels),
  pageTextEntry: (id) => gqlConnect('text', { id })(Text),
  process: (id) => gqlConnect('process', { id })(Process),
  imageBlurb: (id) => gqlConnect('imageBlurb', { id })(ImageBlurb),
}

export const getComponent = (id, type, pathname) => {
  if (type in components) {
    let _type = type
    if(['/','/home', '/home-1'].includes(pathname)) {
      if(_type === 'list') {
        _type = 'newList'
      }
    }
    const Component = components[_type](id)
    return createReactClass({
      displayName: type,
      render: function () {
        return <Component style={{ width: '100%' }} />
      },
    })
  }
  return null
}

export const getPage = (slug) => {
  const PageComp = gqlConnect('page', { slug })(withSplash(Page))
  return createReactClass({
    displayName: 'Page',
    render: function () {
      return <PageComp key={window.location.pathname} />
    },
  })
}

export const getPost = (section) => {
  const PostComp =
    section === '/news' || section === 'news'
      ? gqlConnect('post', { section })(withSplash(Post))
      : gqlConnect('component', { section })(withSplash(ChannelEntry))
  return createReactClass({
    displayName: 'Post',
    render: function () {
      return <PostComp key={window.location.pathname} />
    },
  })
}

export const getHomepage = () => {
  const Homepage = gqlConnect('homepage')(withSplash(Page))
  return createReactClass({
    displayName: 'Homepage',
    render: function () {
      return <Homepage />
    },
  })
}

export const getSearchPage = (target) => {
  const SearchPageComp = gqlConnect('search', { target: target })(
    withSplash(SearchPage),
  )
  return createReactClass({
    displayName: 'SearchPage',
    render: function () {
      return <SearchPageComp />
    },
  })
}
