import React, { Component } from 'react'
import { ImageSlider as ISComp, withSizes } from '../common'
import { withRouter } from 'react-router-dom'
import ImageSliderNextArrow from './ImageSliderNextArrow'
import ImageSliderPrevArrow from './ImageSliderPrevArrow'

class ImageSlider extends Component {
  render() {
    const { imageSliderItems } = this.props.gql
    const { getStyle, history } = this.props

    return (
      <div style={getStyle(styles.container)}>
        <ISComp
          data={imageSliderItems}
          settings={settings}
          history={history}
          styles={getStyle(styles.slider)}
        />
      </div>
    )
  }
}

const settings = {
  dots: false,
  arrows: true,
  nextArrow: <ImageSliderNextArrow />,
  prevArrow: <ImageSliderPrevArrow />,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  centerMode: false,
  responsive: [
    {
      breakpoint: 1290,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 950,
      settings: {
        slidesToShow: 1,
        arrows: false,
        dots: true,
      },
    },
    {
      breakpoint: 460,
      settings: {
        slidesToShow: 1,
        dots: true,
      },
    },
  ],
}

const styles = {
  container: {
    display: 'flex',
    md: {
      display: 'unset',
    },
  },
  slider: {
    container: {
      backgroundColor: 'transparent',
      justifyContent: 'center',
      marginTop: -40,
      paddingTop: 0,
      paddingBottom: 0,
      height: 425,
      overflow: 'hidden',
      maxWidth: 1360,
    },
    item: {
      height: '100%',
    },
    xs: {
      container: {
        marginTop: -75,
      },
    },
    navLink: {
      height: '100%',
      width: '100%',
    },
  },
}

export default withRouter(withSizes(ImageSlider))
