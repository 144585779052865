import React, { Component } from 'react'
import { Article as ArticleComp } from './common'

class Article extends Component {
  render() {
    const { data, isNewsPost } = this.props

    const _styles = styles(isNewsPost)

    if(data.dropCap) {
      for (let body of data.newsPostBody) {
        if (body.typeHandle === 'text') {
          body.text = body.text.replace('<p', '<p class="dropCap"')
          break
        }
      }
    }

    return <ArticleComp data={data} styles={_styles} />
  }
}

Article.defaultProps = {
  isNewsPost: true,
}

const styles = (isNewsPost) => {
  return {
    container: {
      borderTopWidth: isNewsPost ? 1 : 0,
      borderTopColor: '#562A31',
      margin: 0,
      marginLeft: 0,
      marginRight: 0,
      marginBottom: isNewsPost ? 100 : 25,
      paddingTop: isNewsPost ? 25 : 0,
      justifyContent: 'flex-start',
      width: '100%',
    },
    supplement: {
      content: {
        imgCaption: {
          color: '#862C3C',
        },
        quote: {
          color: '#E86956',
          fontFamily: 'capitolina',
          lineHeight: 'normal',
          marginTop: 50,
          marginBottom: 50,
        },
      },
    },
    paragraph: {
      container: {
        color: '#404144',
      },
    },
  }
}

export default Article
