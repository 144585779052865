import React, { Component } from 'react'
import { ContactForm, Footer, SearchBar, Feed } from './index'
import { Page, Section, withSizes } from './common'
import { withRouter } from 'react-router-dom'
import { getPostPreview } from '../utils/GetPostPreview'
import { setDarkMode } from '../redux/actions/navBar'
import { connect } from 'react-redux'

class SearchPage extends Component {
  state = {
    sections: null,
    header: null,
    searchText: this.props.location.search.replace('?q=', ''),
  }

  componentDidMount() {
    const { getSections, getHeader } = this
    const { setDarkMode } = this.props

    setDarkMode(false)
    this.setState({
      header: getHeader(),
      sections: getSections(),
    })
  }

  getHeader = () => {
    const { getStyle } = this.props
    return {
      styles: getStyle(styles.header),
      scrollImg: undefined,
    }
  }
  getSections = () => {
    const { searchText } = this.state
    const { data } = this.props.gql

    let items =
      searchText.length <= 0
        ? []
        : data.data.entries
            .map((entry) => {
              return {
                title: entry.title,
                text: getPostPreview(entry),
                searchScore: entry.searchScore,
                imageUrl: entry.heroImage[0]?.url,
                buttonText: 'View',
                itemUrl: entry.uri,
                pageEntry: entry.uri,
                date: entry.postDate || null,
              }
            })
            .sort((a, b) => {
              return a.searchScore < b.searchScore ? 1 : -1
            })

    return (
      <Section styles={styles.section}>
        <div style={styles.headerText}>Search</div>
        <SearchBar searchText={searchText} />
        <Feed items={items} useGql={false} itemLimit={5} />
      </Section>
    )
  }
  render() {
    const { sections, header } = this.state
    return (
      <div style={styles.container}>
        <Page
          style={styles.page}
          header={header}
          sections={sections}
          showFooter
          showContactForm
          footer={<Footer />}
          contactForm={<ContactForm />}
        />
      </div>
    )
  }
}

const styles = {
  container: {
    backgroundColor: 'white',
  },
  page: {},
  section: {
    container: {
      padding: 50,
      paddingTop: 35,
      paddingBottom: 35,
    },
  },
  header: {
    container: {
      height: 120,
    },
  },
  headerText: {
    color: '#852D3D',
    fontSize: 55,
    fontFamily: 'capitolina',
    fontWeight: 'bold',
    marginBottom: 50,
  },
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDarkMode: (isDarkMode) => dispatch(setDarkMode(isDarkMode)),
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(withRouter(withSizes(SearchPage)))
