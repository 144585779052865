/* eslint-disable react/display-name */
import React from 'react'
import SplashScreen from '../components/SplashScreen'
export const withSplash = (Component, fadeTime = 0.3) => {
  return class extends React.Component {
    static onLoadingSelf = true

    constructor(props) {
      super(props)

      this.state = {
        opacity: this.props.loading ? 1 : 0,
        display: this.props.loading ? 'block' : 'none',
        transition: `opacity ${fadeTime}s`,
        zIndex: 100,
      }
    }

    componentDidUpdate(prevProps) {
      const { loading } = this.props
      if (prevProps.loading && !loading) {
        this.setState({
          opacity: 0,
        })
        setTimeout(() => {
          this.setState({
            display: 'none',
          })
        }, fadeTime * 1000)
      }
    }

    render() {
      const { loading } = this.props

      return (
        <React.Fragment>
          {loading ? null : <Component {...this.props} />}
          <SplashScreen styles={this.state} />
        </React.Fragment>
      )
    }
  }
}
