import React, { Component } from 'react'
import { Section, Page, withSizes } from './common'
import Footer from './Footer'
import Article from './Article'
import { gqlConnect } from 'react-connect-graphql-beta'
import { monthNames } from '../utils/MonthNames'
import { connect } from 'react-redux'
import { setDarkMode } from '../redux/actions/navBar'
import { withSplash } from '../hocs/withSplash'

class TermsConditions extends Component {
  constructor(props) {
    super(props)

    this.state = {
      header: this.getHeader(),
      sections: this.getSections(),
    }
  }

  componentDidMount() {
    const { setDarkMode } = this.props
    setDarkMode(false)
  }

  componentWillUnmount() {
    const { setDarkMode } = this.props
    setDarkMode(true)
  }

  getHeader = () => {
    const { getStyle } = this.props
    return {
      styles: getStyle(styles.header),
      scrollImg: undefined,
    }
  }

  getSections = () => {
    const { data } = this.props.gql
    const { getStyle } = this.props

    const newText = data.privacyText.replace(/(\r\n|\n|\r)/gm, '')

    const articleData = {
      newsPostBody: [
        {
          typeHandle: 'text',
          text: newText,
        },
      ],
    }

    const date = new Date(data.privacyDate)

    const dateStr = `${
      monthNames[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`

    return (
      <Section styles={styles.section}>
        <div style={getStyle(styles.topWrapper)}>
          <div style={styles.headerText}>{data.title}</div>
          <div style={styles.date}>{dateStr}</div>
        </div>
        <Article styles={styles.article} data={articleData} />
      </Section>
    )
  }
  render() {
    const { header } = this.state
    const { getSections } = this

    return (
      <Page
        styles={styles.page}
        header={header}
        sections={getSections()}
        showFooter={true}
        showContactForm={false}
        footer={<Footer />}
      />
    )
  }
}

const styles = {
  page: {
    container: {
      backgroundColor: 'white',
    },
  },
  section: {
    container: {
      paddingLeft: 50,
      paddingRight: 50,
      paddingBottom: 50,
    },
  },
  header: {
    container: {
      height: 175,
    },
  },
  topWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    xs: {
      alignItems: 'flex-start',
    },
  },
  headerText: {
    color: '#852D3D',
    fontSize: 55,
    fontFamily: 'capitolina',
    fontWeight: 'bold',
  },
  date: {
    color: '#404144',
    fontSize: 30,
    marginBottom: 100,
  },
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDarkMode: (isDarkMode) => dispatch(setDarkMode(isDarkMode)),
  }
}

export default connect(
  null,
  mapDispatchToProps,
)(gqlConnect('terms')(withSizes(withSplash(TermsConditions))))
