import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setDarkMode } from '../redux/actions/navBar'
import { Reveal } from 'react-reveal'

class NotFound extends Component {
    componentDidMount() {
        const { setDarkMode } = this.props

        setDarkMode(true)
    }

    render() {
        return (
          <Reveal effect="fadeInUp-Blurb" duration={1000}>
            <div style={styles.container}>
                <div style={styles.text}>Page not found</div>
                <a style={styles.link} href={'/'}>Click here to return to the homepage</a>
            </div>
          </Reveal>
        )
    }
}

const styles = {
    container: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingTop: 200,
    },
    text: {
        color: "#FFFFFF",
        fontSize: 42,
    },
    link: {
        fontSize: 16,
        color: '#E86956',
    },
}

const mapDispatchToProps = (dispatch) => {
    return {
        setDarkMode: (darkMode) => dispatch(setDarkMode(darkMode)),
    }
}

export default connect(null, mapDispatchToProps)(NotFound)
