export const setDarkMode = (isDarkMode) => {
  return {
    type: 'SET_DARK_MODE',
    payload: isDarkMode,
  }
}

export const setGradient = (hasGradient) => {
  return {
    type: 'SET_GRADIENT',
    payload: hasGradient,
  }
}

export const setScrollTrigger = (trigger) => {
  return {
    type: 'SET_SCROLL_TRIGGER',
    payload: trigger,
  }
}
