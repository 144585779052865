import React, { Component } from 'react'
import { NavLink } from './common'
import gsap, { Power2 } from 'gsap'
import { getFeed } from '../utils/ParseNavigationData'

class ServicesMobile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      linkHover: false,
      activeLink: null,
      activeColumn: null,
      data: [],
      allButtonHovered: false,
    }
  }

  componentDidMount() {
    this.setState({
      data: getFeed(this.props.gql.data, this.props.entry),
    })
  }

  hoverLink = (index, colIndex) => {
    this.setState({
      linkHover: true,
      activeLink: index,
      activeColumn: colIndex,
    })
  }

  leaveHoverLink = () => {
    this.setState({
      linkHover: false,
      activeLink: null,
      activeColumn: null,
    })
  }

  open = () => {
    const { data } = this.state

    const numCategories = data.length
    let numItems = 1

    data.forEach((d) => {
      d.links.forEach((l) => {
        numItems++
      })
    })

    const height = 40 * numItems + 50 * numCategories + 70

    const duration = 0.25
    const tl = gsap.timeline({
      smoothChildTiming: true,
      defaults: { duration: duration, ease: Power2.easeOut },
    })
    tl.to('#servicesContainer', { display: 'flex' }, 0)

    tl.to(
      '#servicesContainer',
      { height: height, opacity: 1, paddingTop: 25, paddingBottom: 20 },
      0,
    )
  }

  close = () => {
    const duration = 0.25
    const tl = gsap.timeline({
      smoothChildTiming: true,
      defaults: { duration: duration, ease: Power2.easeOut },
    })

    tl.to(
      '#servicesContainer',
      { opacity: 0, height: 0, paddingTop: 0, paddingBottom: 0 },
      0,
    )
    tl.to('#servicesContainer', { display: 'none' }, duration)
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.display !== this.props.display) {
      if (this.props.display) {
        this.open()
      } else {
        this.close()
      }
    }
  }

  render() {
    const {
      activeLink,
      linkHover,
      activeColumn,
      data,
      allButtonHovered,
    } = this.state
    const { history, toggle, entry } = this.props
    const { hoverLink, leaveHoverLink } = this

    const image = require('../assets/ReadMore.png')
    const hoverImage = require('../assets/ReadMore_hover_light.png')

    return (
      <div id="servicesContainer" style={styles.services.container}>
        {data.map((s, colIndex) => {
          return (
            <React.Fragment key={colIndex}>
              <div style={styles.services.header}>{s.heading}</div>
              <div style={styles.services.sectionWrapper}>
                {s.links.map((l, index) => {
                  return (
                    <div key={index} style={styles.services.itemWrapper}>
                      <NavLink
                        onMouseEnter={() => hoverLink(index, colIndex)}
                        onMouseLeave={() => leaveHoverLink()}
                        style={{
                          ...styles.services.item,
                          color:
                            activeColumn === colIndex &&
                            activeLink === index &&
                            linkHover &&
                            styles.services.item.hover
                              ? styles.services.item.hover.color
                              : styles.services.item.color,
                        }}
                        item={{ url: l.url }}
                        history={history}
                        useRouter={true}>
                        {l.title}
                      </NavLink>
                    </div>
                  )
                })}
              </div>
            </React.Fragment>
          )
        })}
        <div>
          <NavLink
            onMouseEnter={() => this.setState({ allButtonHovered: true })}
            onMouseLeave={() => this.setState({ allButtonHovered: false })}
            style={{
              ...styles.services.item,
              marginTop: 12,
              marginBottom: 12,
              fontStyle: 'italic',
              fontFamily: 'capitolina, serif',
              color: allButtonHovered ? '#FFFFFF' : '#EA6852',
            }}
            item={{ url: entry.slug }}
            history={history}
            useRouter={true}>
            {`All ${entry.title}`}
          </NavLink>
          <img
            src={allButtonHovered ? hoverImage : image}
            alt="Read More"
            style={{
              height: 13,
              width: 13,
              marginLeft: 5,
              marginBottom: 2,
            }}
          />
        </div>
      </div>
    )
  }
}

const styles = {
  services: {
    container: {
      height: 0,
      opacity: 0,
      backgroundColor: '#562A31',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 25,
      display: 'none',
      flexDirection: 'column',
      marginLeft: -25,
      marginRight: -25,
    },
    sectionWrapper: {
      paddingBottom: 10,
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      fontSize: 22,
      fontFamily: 'acumin-pro-condensed',
      textTransform: 'uppercase',
      letterSpacing: 1.25,
      color: '#E86956',
      paddingBottom: 10,
    },
    itemWrapper: {
      paddingBottom: 10,
      display: 'inline-block',
    },
    item: {
      padding: 0,
      margin: 0,
      display: 'inline-block',
      fontSize: 20,
      color: '#EDE8E4',
      hover: {
        color: '#E86956',
      },
    },
  },
}

export default ServicesMobile
