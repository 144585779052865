import React, { Component } from 'react'
import { withSizes } from './common'
import { gqlConnect } from 'react-connect-graphql-beta'

class ContactChannels extends Component {
  state = {
    activeEmailHover: -1,
    activePhoneHover: -1,
    fullWidth: false,
  }

  onResize = () => {
    const { fullWidth } = this.state

    if (window.innerWidth < 890 && !fullWidth) {
      this.setState({
        fullWidth: true,
      })
    } else if (window.innerWidth >= 890 && fullWidth) {
      this.setState({
        fullWidth: false,
      })
    }
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.onResize())

    if (window.innerWidth < 890) {
      this.setState({
        fullWidth: true,
      })
    } 
  }

  onHoverEmail = (index) => {
    this.setState({
      activeEmailHover: index,
    })
  }
  leaveHoverEmail = () => {
    this.setState({
      activeEmailHover: -1,
    })
  }
  onHoverPhone = (index) => {
    this.setState({
      activePhoneHover: index,
    })
  }
  leaveHoverPhone = () => {
    this.setState({
      activePhoneHover: -1,
    })
  }
  renderChannel = (channel, index) => {
    const { getStyle } = this.props
    const {
      onHoverEmail,
      leaveHoverEmail,
      onHoverPhone,
      leaveHoverPhone,
    } = this
    const { activeEmailHover, activePhoneHover, fullWidth } = this.state

    return (
      <div
        key={index}
        style={{
          ...getStyle(styles.channel),
          width: fullWidth ? '100%' : 380,
        }}>
        <div style={styles.title}>{channel.title}</div>
        <div style={styles.textWrapper}>
          {channel.entries.map((entry, subIndex) => {
            if (entry.isEmail) {
              const emailHref = entry.text.split(': ')
              return (
                <span style={styles.text}>
                  {`${emailHref[0]}: `}
                  <a
                    onMouseEnter={() => onHoverEmail(index)}
                    onMouseLeave={leaveHoverEmail}
                    key={subIndex}
                    style={{
                      ...styles.text,
                      textDecoration:
                        activeEmailHover === index ? 'underline' : 'none',
                    }}
                    href={`mailto:${emailHref[1]}`}>
                    {emailHref[1]}
                  </a>
                </span>
              )
            } else if (entry.isPhone) {
              const phoneHref = entry.text.split(': ')
              return (
                <span>
                  {`${phoneHref[0]}: `}
                  <a
                    onMouseEnter={() => onHoverPhone(index)}
                    onMouseLeave={leaveHoverPhone}
                    href={`tel:${phoneHref[1]
                      .replace('+', '')
                      .replace('.', '')
                      .replace('.', '')
                      .replace('-', '')
                      .replace('-', '')}`}
                    style={{
                      ...styles.text,
                      textDecoration:
                        activePhoneHover === index ? 'underline' : 'none',
                    }}>
                    {phoneHref[1]}
                  </a>
                </span>
              )
            } else {
              return (
                <div key={subIndex} style={styles.text}>
                  {entry.text}
                </div>
              )
            }
          })}
        </div>
      </div>
    )
  }
  extractChannels = (data) => {
    let channels = []
    if (data.enabled1) {
      channels.push({
        title: data.title1,
        entries: data.contactEntries.map((entry) => {
          let text = ''
          let isEmail = false
          let isPhone = false
          if (entry.__typename.includes('phoneChannel')) {
            text = `${entry.phoneTitle}: ${entry.phoneNumber}`
            isPhone = true
          } else if (entry.__typename.includes('emailChannel')) {
            text = `${entry.emailTitle}: ${entry.emailAddress}`
            isEmail = true
          } else if (entry.__typename.includes('textChannel')) {
            text = `${entry.textTitle}: ${entry.channelText}`
          }
          return {
            text: text,
            isPhone: isPhone,
            isEmail: isEmail,
          }
        }),
      })
    }
    if (data.enabled2) {
      channels.push({
        title: data.title2,
        entries: data.contactEntries2.map((entry) => {
          let text = ''
          let isEmail = false
          let isPhone = false
          if (entry.__typename.includes('phoneChannel')) {
            text = `${entry.phoneTitle}: ${entry.phoneNumber}`
            isPhone = true
          } else if (entry.__typename.includes('emailChannel')) {
            text = `${entry.emailTitle}: ${entry.emailAddress}`
            isEmail = true
          } else if (entry.__typename.includes('textChannel')) {
            text = `${entry.textTitle}: ${entry.channelText}`
          }
          return {
            text: text,
            isPhone: isPhone,
            isEmail: isEmail,
          }
        }),
      })
    }
    if (data.enabled3) {
      channels.push({
        title: data.title3,
        entries: data.contactEntries3.map((entry) => {
          let text = ''
          let isEmail = false
          let isPhone = false
          if (entry.__typename.includes('phoneChannel')) {
            text = `${entry.phoneTitle}: ${entry.phoneNumber}`
            isPhone = true
          } else if (entry.__typename.includes('emailChannel')) {
            text = `${entry.emailTitle}: ${entry.emailAddress}`
            isEmail = true
          } else if (entry.__typename.includes('textChannel')) {
            text = `${entry.textTitle}: ${entry.channelText}`
          }
          return {
            text: text,
            isEmail: isEmail,
            isPhone: isPhone,
          }
        }),
      })
    }

    return channels
  }
  render() {
    const { data } = this.props.gql
    const { renderChannel, extractChannels } = this

    const channels = extractChannels(data)

    return (
      <div style={styles.container}>
        {channels.map((channel, index) => {
          return renderChannel(channel, index)
        })}
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: 1230,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 50,
  },
  channel: {
    height: 225,
    backgroundColor: '#DCD3CB',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 25,
    marginLeft: 15,
    marginRight: 15,
    marginTop: 15,
    marginBottom: 15,
    sm: {
      marginLeft: 0,
      marginRight: 0,
    },
    xs: {
      height: 300,
    },
  },
  title: {
    color: '#852D3D',
    fontSize: 22,
    textTransform: 'uppercase',
    letterSpacing: 1.25,
    fontWeight: 'bold',
    borderBottomStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '#7D7773',
    width: '100%',
    textAlign: 'center',
    paddingBottom: 25,
    marginBottom: 25,
    height: 75,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: 100,
  },
  text: {
    color: '#404144',
    fontSize: 18,
    fontStyle: 'italic',
    textAlign: 'center',
  },
  email: {
    color: '#404144',
  },
}

export default gqlConnect('contactChannels')(withSizes(ContactChannels))
