import React, { Component } from 'react'
import {  } from 'reactstrap'
import mergeStyles from './utils/StyleMerge'

class DropdownMenu extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>placeholder</div>
    )
  }
}

DropdownMenu.defaultProps = {

}

const defaultStyles = {

}

export default mergeStyles(defaultStyles)(DropdownMenu)
