import React, { Component } from 'react'
import {
  Page as PageComp,
  withSizes,
} from './common'
import Footer from './Footer'
import ContactForm from './ContactForm'
import { getComponent } from '../utils/ComponentFactory'
import { connect } from 'react-redux'
import { setDarkMode } from '../redux/actions/navBar'
import { withRouter } from 'react-router-dom'
import DocumentMeta from 'react-document-meta'
import {
  getMeta,
  _title,
  _description,
  _image,
  _keywords,
  parseKeywords,
} from '../utils/defaultMeta'
import NotFound from './NotFound'
import {formatMetaTitle} from "../utils/formatMetaTitle";

class Page extends Component {
  constructor(props) {
    super(props)

    this.fixedHeaderHeight = window.innerWidth <= 767 ? 350 : 600

    this.state = {
      sections: this.getSections(),
      header: this.getHeader(),
      activeSlideIndex: -1,
      slideThemes: [],
    }
  }

  componentDidMount() {
    const { gql, setDarkMode } = this.props


    if(!gql.children) {
      this.setState({
        notFound: true,
      })

      return
    }

    let themes = []
    gql.slides.forEach((slide) => {
      themes.push(slide.slideType)
    })

    setDarkMode(themes[0] === 'dark')

    this.setState({
      slideThemes: themes,
    })

    window.addEventListener('resize', this.onResize)
  }

  onResize = () => {
    this.fixedHeaderHeight = window.innerWidth <= 767 ? 350 : 600
    this.setState({
      header: this.getHeader()
    })
  }

  setNavbarTheme = (oldIndex, newIndex) => {
    const { slideThemes } = this.state
    const { setDarkMode } = this.props

    if (slideThemes[oldIndex] !== slideThemes[newIndex]) {
      setDarkMode(slideThemes[newIndex] === 'dark')
    }
  }

  getSections = () => {
    const { gql } = this.props
    return gql.children.map((child, index) => {
      const Component = getComponent(child.id, child.typeHandle)
      return <Component key={index} />
    })
  }

  getHeader = () => {
    const { gql, getStyle } = this.props
    const { setNavbarTheme, fixedHeaderHeight } = this

    if (!gql.slides[0]?.image[0]) {
      styles.header.container = {
        ...styles.header.container,
        backgroundColor: '#021E27',
      }
    }
    if (!gql.headerFullHeight) {
      styles.header.container = {
        ...styles.header.container,
        height: fixedHeaderHeight,
      }
    } else {
      styles.header.container = {
        ...styles.header.container,
        height: '100vh',
      }
    }

    const _slides = gql.slides?.map((slide) => {
      const titles = slide.text?.split('\n')
      return {
        source: slide.image[0]?.url,
        title: titles.map((t) => {
          return {
            text: t,
            style: {
              marginBottom: 15,
              marginLeft: 35,
              marginRight: 35,
              fontSize: 60,
              fontFamily: 'capitolina, serif',
              fontWeight: 700,
              textShadow: 'rgba(0, 0, 0, 0.75) 2px 2px 5px',
              lineHeight: 1,
              md: {
                fontSize: 45,
              },
            },
          }
        }),
      }
    })

    return {
      styles: getStyle(styles.header),
      buttons: gql?.buttons,
      slideshow: {
        slides: _slides,
        options: {
          duration: 5000,
          transitionDuration: 500,
          infinite: true,
          arrows: false,
          indicators: false,
          onChange: setNavbarTheme,
        },
      },
      scrollImg: gql.headerFullHeight
        ? require('../assets/HeaderScroll.png')
        : undefined,
    }
  }

  render() {
    const { sections, header, notFound } = this.state
    const { getStyle, gql, metadata } = this.props

    header.styles = getStyle(styles.header)

    const metaTitle = gql?.slug === 'home' ? metadata?.siteName + ' ' + metadata?.titleDelimiter + ' ' + 'Automotive, RV, and Marine'
      : formatMetaTitle(gql?.title || _title, metadata?.siteName, metadata?.shortSiteName, metadata?.titleDelimiter, metadata?.siteNameCharacterLimit)

    return notFound ? <NotFound /> : (
      <DocumentMeta
        {...getMeta(
          metaTitle,
          gql.seoDescription || _description,
          gql?.seoImage[0]?.url || _image,
          gql.seoKeywords.length > 0
            ? parseKeywords(gql.seoKeywords)
            : _keywords,
        )}>
        <PageComp
          styles={styles}
          footer={<Footer />}
          contactForm={<ContactForm />}
          sections={sections}
          header={header}
          {...this.props}
        />
      </DocumentMeta>
    )
  }
}

const styles = {
  container: {},
  header: {
    container: {},
    fadeImages: {
      title: {
        fontFamily: 'capitolina, serif',
        fontWeight: 'bold',
      },
    },
    slideshow: {
      container: {
        height: '100%',
        width: '100%',
      },
      slider: {
        slider: {
          height: '100%',
        },
      },
    },
    md: {
      fadeImages: {
        title: {
          fontSize: 40,
          padding: 10,
          paddingLeft: 35,
          paddingRight: 35,
        },
      },
      scroll: {
        height: 50,
        width: 50,
        bottom: 25,
      },
    },
  },
}

const mapStateToProps = (state) => {
  return {
    metadata: state.metadata
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setDarkMode: (isDarkMode) => dispatch(setDarkMode(isDarkMode)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withSizes(Page)))
